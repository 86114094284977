import { defineStore } from 'pinia'
import { reactive } from 'vue'
import { AddSubscriptionResponse, PaymentMethod, PaymentType, ProductPrice, Subscription, SubscriptionItem, SubscriptionStatus } from '@webapp/gp/GroupAdminModel'
import { useGroupAdminApi } from './GroupAdminApi'
import { AsyncData, LoadMode } from '@webapp/util/AsyncData'
import { AsyncDataStore, VersionedKeyList } from '@webapp/util/AsyncDataStore'
import { TokenManager } from '@webapp/auth/TokenManager'
import { DateTime } from 'luxon'
import { assignExisting, isDefined } from '@webapp/util/TypeScriptUtil'
import { DateTimeUtil } from '@/util/LuxonUtil'
import { useUserStore } from './UserStore'
import { usePaymentMethodStore } from './PaymentMethodStore'

export const useSubscriptionStore = defineStore('subscriptions', () => {
    const asyncSubscriptions = reactive(new Map<string, AsyncData<Subscription>>())

    const gp = useGroupAdminApi()
    const userStore = useUserStore()
    const paymentMethodStore = usePaymentMethodStore()

    function getLoadedSubscription(id: string | undefined) {
        return asyncSubscriptions.get(id ?? '')?.data
    }

    function getLoadedSubscriptionList(ids: (string | undefined)[]) {
        return ids.map(id => asyncSubscriptions.get(id ?? '')).map(a => a?.data).filter(isDefined)
    }

    function getAsyncKeyListForUser(userId: string | undefined, loadMode = LoadMode.TrackOnly) {
        const loadAsync = async (ids: string[], requestTime: DateTime) => {
            const path = `users/${userId}/subscriptions`
            const plainSubs = await gp.getPlainCollectionAsync(path, "paymentMethod")
            console.log(`Loaded ${plainSubs.length} payment methods at ${path}`)
            return processExpandedSubscriptions(plainSubs, requestTime)
        }
        return AsyncDataStore.getAsyncKeyListForRelatedItems(TokenManager.userId, "User",
            userStore.asyncSubscriptionKeys, asyncSubscriptions, "Subscription", loadMode, loadAsync)
    }

    function getAsyncKeyListForSelf(loadMode = LoadMode.TrackOnly) {
        // NOTE: use the actual user id, because we don't want $me as a cache key
        return getAsyncKeyListForUser(TokenManager.userId, loadMode)
    }

    async function getSubscriptionListForUserAsync(userId: string | undefined) {
        const keyList = await getAsyncKeyListForUser(userId, LoadMode.EnsureLoaded)?.whenLoadCompleted
        return getLoadedSubscriptionList(keyList?.keys ?? [])
    }

    async function addAsync(planId: string) {
        const plainResponse = await gp.postPlainAsync('subscriptions', undefined, { planId })
        return plainResponse as AddSubscriptionResponse
    }

    async function changePlanAsync(subscriptionId: string, planId: string) {
         await gp.postPlainAsync(`subscriptions/${subscriptionId}/changeplan`, undefined, { planId })
    }
    
    async function cancelAsync(subscriptionId: string) {
        await gp.postPlainAsync(`subscriptions/${subscriptionId}/cancel`)
    }

    function plainToSubscription(p: any) {
        const sub = assignExisting(new Subscription(), p)
        sub.startDate = DateTimeUtil.fromAPI(p.startDate)
        sub.currentPeriodStart = DateTimeUtil.fromAPI(p.currentPeriodStart)
        sub.currentPeriodEnd = DateTimeUtil.fromAPI(p.currentPeriodEnd)
        sub.createdDate = DateTimeUtil.fromAPI(p.createdDate)
        sub.modifiedDate = DateTimeUtil.fromAPI(p.modifiedDate)
        sub.items = p.items?.map((pi: any) => plainToSubscriptionItem(pi)) ?? []
        return sub
    }

    function plainToSubscriptionItem(p: any) {
        const item = assignExisting(new SubscriptionItem(), p)
        item.price = plainToProductPrice(p.price)
        return item
    }

    function plainToProductPrice(p: any) {
        const price = assignExisting(new ProductPrice(), p)
        return price
    }

    function processExpandedSubscriptions(plainItems: any[], requestTime: DateTime) {
        paymentMethodStore.addPaymentMethodsToStore(plainItems.map(p => p.paymentMethod).filter(isDefined), requestTime)
        return AsyncDataStore.createItemMap(plainItems, "Subscription", plainToSubscription, s => s.id!)
    }

    return {
        asyncSubscriptions,

        getLoadedSubscription,
        getLoadedSubscriptionList,
        getAsyncKeyListForUser,
        getAsyncKeyListForSelf,
        getSubscriptionListForUserAsync,
        addAsync,
        changePlanAsync,
        cancelAsync,
    }
})
