import { useAppInsights } from 'vue3-application-insights'

export function useTelemetry() {
    const appInsights = useAppInsights()

    function setTelemetryContext(context: TelemetryContext) {
        if (context.appName) {
            // should only set this up once
            appInsights.addTelemetryInitializer(item => {
                item.tags ??= {}
                item.tags["ai.cloud.role"] = context.appName
            })
        }
        if (context.appVersion) {
            appInsights.context.application.ver = context.appVersion
        }
        if (context.appSessionId) {
            appInsights.context.session.id = context.appSessionId
        }
        if (context.userId) {
            appInsights.context.user.id = context.userId
        }
    }
    
    function trackPageView(pageName: string, properties?: CustomData) {
        appInsights.trackPageView({ name: pageName, properties })
    }
    
    function trackEvent(eventName: string, properties?: CustomData) {
        appInsights.trackEvent({ name: eventName, properties })
    }
    
    function trackException(error: unknown, properties?: CustomData) {
        const exception = error instanceof Error ? error : new Error(String(error))
        appInsights.trackException({ exception, properties })
    }
    
    return {
        setTelemetryContext,
        trackPageView,
        trackEvent,
        trackException,
    } as Telemetry
}

export interface Telemetry {
    setTelemetryContext: (context: TelemetryContext) => void,
    trackPageView: (pageName: string, properties?: CustomData) => void,
    trackEvent: (eventName: string, properties?: CustomData) => void,
    trackException: (error: unknown, properties?: CustomData) => void,    
}

export interface CustomData {
    [key: string]: any    
}

export interface TelemetryContext {
    appName?: string,
    appVersion?: string,
    appSessionId?: string,
    userId?: string,
}
