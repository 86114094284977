<template>
  <div>
    <h1>Test sign in</h1>
    <form>
      <label for="usernameInput" class="form-label">Name</label>
      <div class="mb-3">
        <input type="text" id="usernameInput" ref="usernameInput" v-model="username" required :disabled="!canEdit"/><span class="text-secondary"> @{{testDomain}}</span>
      </div>
      <div class="mb-3">
        <input type="password" id="passwordInput" v-model="password" required :disabled="!canEdit"/>
      </div>
      <button type="submit" class="btn btn-primary" :disabled="!canSignIn" @click="signIn">
        <spinner v-if="busy"></spinner>
        {{signInText}}
      </button>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { usePageTitle } from '@/util/AppUtil'
import { useIdentityApi } from '@/auth/IdentityApi'
import Spinner from '@/util/Spinner.vue'

usePageTitle("Test sign in")

const identityApi = useIdentityApi()

const testDomain = "example.com"

const username = ref('')
const password = ref('')
const busy = ref(false)

const canEdit = computed(() => !busy.value)
const canSignIn = computed(() => username.value && password.value && !busy.value)
const signInText = computed(() => busy.value ? "Signing in..." : "Sign in")

const usernameInput = ref<HTMLInputElement>()

onMounted(() => {
  setTimeout(() => {
    usernameInput.value?.focus()
    usernameInput.value?.select()
  })
})

async function signIn(e: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
  e.preventDefault()
  busy.value = true

  const response = await identityApi.signInAsync(`${username.value}@${testDomain}`, password.value)
  window.location.href = "/authredirect?access_token=" + encodeURIComponent(response.access_token)
}
</script>
