import { Api } from "@webapp/util/Api"
import { useTokenManager } from "@webapp/auth/TokenManager"
import { Environment } from "@webapp/util/Environment"
import { useGroupAdminNotifications } from "./GroupAdminNotifications"

const gpUrl = Environment.get('GROUP_ADMIN_URL')

export function useGroupAdminApi(anonymous = false) {
    const tokenManager = useTokenManager()
    const { getConnectionId } = useGroupAdminNotifications()
    
    return anonymous
        ? new Api(gpUrl, () => '')
        : new Api(gpUrl, tokenManager.getAccessTokenAsync, () => getConnectionId() ?? '')
}
