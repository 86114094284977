import { defineStore } from 'pinia'
import { UserSupervisor } from '@webapp/gp/GroupAdminModel'
import { useGroupAdminApi } from './GroupAdminApi'
import { AsyncData, LoadMode } from '@webapp/util/AsyncData'
import { AsyncDataStore, VersionedKeyList } from '@webapp/util/AsyncDataStore'
import { useUserStore } from './UserStore'
import { DateTime } from 'luxon'
import { DateTimeUtil } from '@webapp/util/LuxonUtil'
import { assignExisting, isDefined } from '@webapp/util/TypeScriptUtil'
import { reactive } from 'vue'
import { TokenManager } from '@/auth/TokenManager'


export const useUserSupervisorStore = defineStore('userSupervisors', () => {
    const asyncUserSupervisors = reactive(new Map<string, AsyncData<UserSupervisor>>())

    const gp = useGroupAdminApi()
    const userStore = useUserStore()

    function getAsyncUserSupervisor(id: string | undefined, loadMode = LoadMode.TrackOnly) {
        return id ? getAsyncUserSupervisorList([id], loadMode)[0] : undefined
    }

    function getAsyncUserSupervisorList(ids: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const loadAsync = async (ids: string[], requestTime: DateTime) => {
            const plainItems = await gp.getPlainByIdsAsync("usersupervisors", ids, userSupervisorExpand)
            console.log(`Loaded ${plainItems.length} user supervisors by id: ${plainItems.map(p => p.id).join(", ")}`)
            return processExpandedUserSupervisors(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncItemsByIds(asyncUserSupervisors, ids, "UserSupervisor", loadMode, loadAsync)
    }

    async function getUserSupervisorListAsync(ids: (string | undefined)[]) {
        return (await Promise.all(getAsyncUserSupervisorList(ids, LoadMode.EnsureLoaded).map(a => a.whenLoadCompleted))).filter(isDefined)
    }

    function getAsyncKeyListForSupervisor(supervisorId: string | undefined, loadMode = LoadMode.TrackOnly) {
        supervisorId = supervisorId ?? TokenManager.userId
        return getAsyncKeyListForUser(supervisorId, userStore.asyncSupervisedUserKeys, loadMode, 
            `users/${supervisorId}/supervisedusers`)
    }

    function getForSupervisor(supervisorId: string | undefined, loadMode = LoadMode.TrackOnly) {
        return getAsyncUserSupervisorList(getAsyncKeyListForSupervisor(supervisorId, loadMode)?.data?.keys ?? [])
            .map(a => a.data).filter(isDefined)
    }

    function getAsyncKeyListForSupervisedUser(supervisedUserId: string | undefined, loadMode = LoadMode.TrackOnly) {
        return getAsyncKeyListForUser(supervisedUserId, userStore.asyncSupervisorKeys, loadMode, 
            `users/${supervisedUserId}/supervisors`)
    }

    function getForSupervisedUser(supervisedUserId: string | undefined, loadMode = LoadMode.TrackOnly) {
        return getAsyncUserSupervisorList(getAsyncKeyListForSupervisedUser(supervisedUserId, loadMode)?.data?.keys ?? [])
            .map(a => a.data).filter(isDefined)
    }

    async function addAsync(sup: UserSupervisor) {
        const addCoreAsync = async () => {
            const p = await gp.postPlainAsync("usersupervisors", sup)
            return plainToUserSupervisor(p)
        }            
        const newId = await AsyncDataStore.addToStoreMapAsync(asyncUserSupervisors, "UserSupervisor", s => s.id, addCoreAsync)

        AsyncData.invalidate(userStore.asyncSupervisedUserKeys.get(sup.supervisorId!))
        AsyncData.invalidate(userStore.asyncSupervisorKeys.get(sup.forUserId!))
        return newId
    }

    async function deleteAsync(id: string) {
        const sup = await getAsyncUserSupervisorList([id], LoadMode.EnsureLoaded).at(0)?.whenLoadCompleted
        if (sup) {
            await gp.deleteAsync(`usersupervisors/${id}`)
            AsyncData.setDeleted(asyncUserSupervisors.get(id))
    
            AsyncData.invalidate(userStore.asyncSupervisedUserKeys.get(sup.supervisorId!))
            AsyncData.invalidate(userStore.asyncSupervisorKeys.get(sup.forUserId!))
            AsyncData.invalidate(userStore.asyncPermissions.get(sup.forUserId!))
        }
    }

    function addUserSupervisorsToStore(plainItems: any[], requestTime: DateTime) {
        const userSupervisorMap = processExpandedUserSupervisors(plainItems, requestTime)
        AsyncDataStore.addItemMapToStore(asyncUserSupervisors, "UserSupervisor", userSupervisorMap, requestTime)
    }

    const userSupervisorExpand = "supervisor,foruser"

    function plainToUserSupervisor(p: any) {
        const group = assignExisting(new UserSupervisor(), p)
        group.createdDate = DateTimeUtil.fromAPI(p.createdDate)
        return group
    }

    function processExpandedUserSupervisors(plainItems: any[], requestTime: DateTime) {
        userStore.addUsersToStore(plainItems.map(p => p.Supervisor).filter(isDefined), requestTime)
        userStore.addUsersToStore(plainItems.map(p => p.ForUser).filter(isDefined), requestTime)
        return AsyncDataStore.createItemMap(plainItems, "UserSupervisor", plainToUserSupervisor, sup => sup.id!)
    }

    function getAsyncKeyListForUser(userId: string | undefined,
        relatedKeysStoreMap: Map<string, AsyncData<VersionedKeyList>>,
        loadMode: LoadMode,
        relatedItemsPath: string) {

        const loadRelatedAsync = async (sourceKeys: string[], requestTime: DateTime) => {
            const plainItems = await gp.getPlainCollectionAsync(relatedItemsPath, userSupervisorExpand)
            console.log(`Loaded ${plainItems.length} user supervisors at ${relatedItemsPath}`)
            return processExpandedUserSupervisors(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncKeyListForRelatedItems(userId, "User", relatedKeysStoreMap,
            asyncUserSupervisors, "UserSupervisor", loadMode, loadRelatedAsync)
    }

    return {
        asyncUserSupervisors,

        getAsyncUserSupervisor,
        getAsyncUserSupervisorList,
        getUserSupervisorListAsync,
        getAsyncKeyListForSupervisor,
        getForSupervisor,
        getAsyncKeyListForSupervisedUser,
        getForSupervisedUser,
        addAsync,
        deleteAsync,
        addUserSupervisorsToStore,
    }
})
