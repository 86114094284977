import { defineStore } from 'pinia'
import { reactive } from 'vue'
import { FamilyGroupInvite, InvitationResult, ItemPermissions } from '@webapp/gp/GroupAdminModel'
import { useGroupAdminApi } from './GroupAdminApi'
import { AsyncData, LoadMode } from '@webapp/util/AsyncData'
import { AsyncDataStore } from '@webapp/util/AsyncDataStore'
import { useUserStore } from './UserStore'
import { useFamilyGroupStore } from './FamilyGroupStore'
import { useFamilyGroupMemberStore } from './FamilyGroupMemberStore'
import { TokenManager } from '@webapp/auth/TokenManager'
import { DateTime } from 'luxon'
import { DateTimeUtil } from '@webapp/util/LuxonUtil'
import { assignExisting, isDefined } from '@webapp/util/TypeScriptUtil'
import { useDataGroupStore } from './DataGroupStore'
import { AccessTokenResponse } from '@webapp/auth/AccessTokenResponse'
import { getAsyncPermissionsCore } from './Permissions'
import { addRedactRule } from '@/util/RedactRules'

addRedactRule({
    pattern: new RegExp('^/familygroupinvites/[^/]+$'),
    params: ['code']
})

export const useFamilyGroupInviteStore = defineStore('familyGroupInvites', () => {
    const asyncInvites = reactive(new Map<string, AsyncData<FamilyGroupInvite>>())
    const asyncPermissions = reactive(new Map<string, AsyncData<ItemPermissions>>())

    const gp = useGroupAdminApi()
    const familyGroupStore = useFamilyGroupStore()
    const userStore = useUserStore()
    const dataGroupStore = useDataGroupStore()

    function getAsyncInvite(id: string | undefined, loadMode = LoadMode.TrackOnly) {
        return id ? getAsyncInviteList([id], loadMode)[0] : undefined
    }
    
    function getAsyncInviteList(ids: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const loadAsync = async (idsToLoad: string[], requestTime: DateTime) => {
            const plainItems = await gp.getPlainByIdsAsync("familygroupinvites", idsToLoad, familyGroupInviteExpand)
            console.log(`Loaded ${plainItems.length} family group invites by id: ${plainItems.map(p => p.id).join(", ")}`)
            return processExpandedFamilyGroupInvites(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncItemsByIds(asyncInvites, ids, "FamilyGroupInvite", loadMode, loadAsync)
    }
    
    async function getInviteListAsync(ids: (string | undefined)[]) {
        return (await Promise.all(getAsyncInviteList(ids, LoadMode.EnsureLoaded).map(a => a.whenLoadCompleted))).filter(isDefined)
    }

    function getAsyncKeyListForFamilyGroup(familyGroupId: (string | undefined), loadMode = LoadMode.TrackOnly) {
        const loadInvitesAsync = async (sourceKeys: string[], requestTime: DateTime) => {
            const invitesPath = `familygroups/${familyGroupId}/invites`
            const plainItems = await gp.getPlainCollectionAsync(invitesPath, familyGroupInviteExpand)
            console.log(`Loaded ${plainItems.length} family group invites at ${invitesPath}`)
            return processExpandedFamilyGroupInvites(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncKeyListForRelatedItems(familyGroupId, "FamilyGroup", familyGroupStore.asyncInviteKeys,
            asyncInvites, "FamilyGroupInvite", loadMode, loadInvitesAsync)
    }

    async function getInvitesForFamilyGroupAsync(familyGroupId: string | undefined) {
        const keyList = await getAsyncKeyListForFamilyGroup(familyGroupId, LoadMode.EnsureLoaded).whenLoadCompleted
        return await getInviteListAsync(keyList?.keys ?? []) // should be loaded
    }

    function getAsyncKeyListForUser(userId?: string, loadMode = LoadMode.TrackOnly) {
        userId ??= TokenManager.userId
        const loadInvitesAsync = async (sourceKeys: string[], requestTime: DateTime) => {
            const invitesPath = `users/${userId}/familygroupinvites`
            const plainItems = await gp.getPlainCollectionAsync(invitesPath, familyGroupInviteExpand + ",familyGroup/owner")
            console.log(`Loaded ${plainItems.length} family group invites at ${invitesPath}`)
            return processExpandedFamilyGroupInvites(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncKeyListForRelatedItems(userId, "User", userStore.asyncFamilyGroupInviteKeys,
            asyncInvites, "FamilyGroupInvite", loadMode, loadInvitesAsync)
    }

    function getInvitesForUser(userId?: string, loadMode = LoadMode.EnsureLoaded) {
        return getAsyncInviteList(getAsyncKeyListForUser(userId, loadMode)?.data?.keys ?? [])
            .map(a => a.data).filter(isDefined)
    }

    function getAsyncInviteByCode(id: string, code?: string) {
        const loadAsync = async (idsToLoad: string[], requestTime: DateTime) => {
            const plainItem = await gp.getPlainAsync(`familygroupinvites/${id}`, { code, expand: familyGroupInviteExpand + ",familyGroup/owner" })
            return processExpandedFamilyGroupInvites([plainItem], requestTime)
        }
        return AsyncDataStore.getAsyncItemsByIds(asyncInvites, [id], "FamilyGroupInvite", LoadMode.EnsureLoaded, loadAsync)[0]
    }

    function getAsyncPermissions(id: string | undefined, loadMode = LoadMode.TrackOnly) {
        return getAsyncPermissionsCore(asyncPermissions, id, "FamilyGroupInvite", "familygroupinvites", loadMode)
    }
    
    async function sendAsync(invite: FamilyGroupInvite) {
        const sendCoreAsync = async () => {
            const plainItem = await gp.postPlainAsync(`familygroupinvites`, invite)
            return plainToFamilyGroupInvite(plainItem)
        }
        // NOTE: Stored key list for family group will not be updated automatically
        const inviteId = await AsyncDataStore.addToStoreMapAsync(asyncInvites, "FamilyGroupInvite", inv => inv.id, sendCoreAsync)
        
        if (invite.familyGroupId) {
            familyGroupStore.asyncInviteKeys.delete(invite.familyGroupId)
        }
        return inviteId
    }

    async function cancelAsync(id: string) {
        await gp.postPlainAsync(`familygroupinvites/${id}/cancel`)
        getAsyncInvite(id, LoadMode.Reload) // trigger reload, but don't wait for it
    }

    async function resendAsync(id: string, code?: string, message?: string) {
        await gp.postPlainAsync(`familygroupinvites/${id}/resend`, { message }, { code })
        getAsyncInvite(id, LoadMode.Reload) // trigger reload, but don't wait for it
    }
    
    async function acceptAsync(id: string, code?: string) {
        const r = await gp.postPlainAsync(`familygroupinvites/${id}/accept`, undefined, { code }) as AcceptInviteResult
        getAsyncInvite(id, LoadMode.Reload) // trigger reload, but don't wait for it
        const memberStore = useFamilyGroupMemberStore()
        memberStore.getAsyncKeyListsForUsers([TokenManager.userId], LoadMode.Reload) // trigger reload, but don't wait for it
        return r.tokenResponse
    }
    
    const familyGroupInviteExpand = "senderUser,profileDataGroup/owner,recipientUser"

    function plainToFamilyGroupInvite(p: any) {
        const invite = assignExisting(new FamilyGroupInvite(), p)
        invite.result = p.result ?? InvitationResult.Pending // default
        invite.lastSentDate = DateTimeUtil.fromAPI(p.lastSentDate)
        invite.expirationDate = DateTimeUtil.fromAPI(p.expirationDate)
        invite.closedDate = DateTimeUtil.fromAPI(p.closedDate)
        invite.modifiedDate = DateTimeUtil.fromAPI(p.modifiedDate)
        return invite
    }

    function processExpandedFamilyGroupInvites(plainInvites: any[], requestTime: DateTime) {
        userStore.addUsersToStore(plainInvites.map(p => p.senderUser).filter(isDefined), requestTime)
        userStore.addUsersToStore(plainInvites.map(p => p.recipientUser).filter(isDefined), requestTime)
        familyGroupStore.addFamilyGroupsToStore(plainInvites.map(p => p.familyGroup).filter(isDefined), requestTime)
        dataGroupStore.addDataGroupsToStore(plainInvites.map(p => p.profileDataGroup).filter(isDefined), requestTime)
        return AsyncDataStore.createItemMap(plainInvites, "FamilyGroupInvite", plainToFamilyGroupInvite, inv => inv.id!)
    }

    interface AcceptInviteResult {
        tokenResponse: AccessTokenResponse
    }
    return {
        asyncInvites,
        asyncPermissions,

        getAsyncInvite,
        getAsyncInviteList,
        getInviteListAsync,
        getAsyncKeyListForFamilyGroup,
        getInvitesForFamilyGroupAsync,
        getAsyncKeyListForUser,
        getInvitesForUser,
        getAsyncInviteByCode,
        getAsyncPermissions,
        sendAsync,
        cancelAsync,
        resendAsync,
        acceptAsync,
    }
})
