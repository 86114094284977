import { defineStore } from 'pinia'
import { User, ItemPermissions, UserLimits, UserMetadata } from '@webapp/gp/GroupAdminModel'
import { useGroupAdminApi } from './GroupAdminApi'
import { AsyncData, LoadMode } from '@webapp/util/AsyncData'
import { AsyncDataStore, VersionedKeyList } from '@webapp/util/AsyncDataStore'
import { useDataGroupStore } from './DataGroupStore'
import { getAsyncPermissionsCore } from './Permissions'
import { TokenManager, UserPrivilege } from '@webapp/auth/TokenManager'
import { DateTime } from 'luxon'
import { DateTimeUtil } from '@webapp/util/LuxonUtil'
import { assignExisting, isDefined } from '@webapp/util/TypeScriptUtil'
import { PaginatedList, PatchChange } from '@webapp/util/Api'
import { reactive } from 'vue'

export const useUserStore = defineStore('users', () => {
    const asyncUsers = reactive(new Map<string, AsyncData<User>>())
    const asyncDataGroupMemberKeys = reactive(new Map<string, AsyncData<VersionedKeyList>>())
    const asyncFamilyGroupMemberKeys = reactive(new Map<string, AsyncData<VersionedKeyList>>())
    const asyncFamilyGroupInviteKeys = reactive(new Map<string, AsyncData<VersionedKeyList>>())
    const asyncSubscriptionKeys = reactive(new Map<string, AsyncData<VersionedKeyList>>())
    const asyncPaymentMethodKeys = reactive(new Map<string, AsyncData<VersionedKeyList>>())
    const asyncSupervisorKeys = reactive(new Map<string, AsyncData<VersionedKeyList>>())
    const asyncSupervisedUserKeys = reactive(new Map<string, AsyncData<VersionedKeyList>>())
    const asyncPermissions = reactive(new Map<string, AsyncData<ItemPermissions>>())

    const gp = useGroupAdminApi()
    const dataGroupStore = useDataGroupStore()

    function getAsyncSelf(loadMode = LoadMode.TrackOnly) {
        return getAsyncUser(TokenManager.userId, loadMode)
    }
    
    function getAsyncUser(id: string | undefined, loadMode = LoadMode.TrackOnly) {
        return id ? getAsyncUserList([id], loadMode)[0] : undefined
    }

    async function getSelfPersonIdAsync() {

        const self = await getAsyncSelf(LoadMode.EnsureLoaded)!.whenLoadCompleted
        const selfProfileGroup = await dataGroupStore.getAsyncGroup(self?.profileDataGroupId, LoadMode.EnsureLoaded)?.whenLoadCompleted
        return selfProfileGroup?.startItemId    
    }

    function getAsyncUserList(ids: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        ids.forEach(checkNoAlias)
        const loadAsync = async (ids: string[], requestTime: DateTime) => {
            const plainItems = await gp.getPlainByIdsAsync("users", ids)
            console.log(`Loaded ${plainItems.length} users by id: ${plainItems.map(p => p.id).join(", ")}`)
            return processExpandedUsers(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncItemsByIds(asyncUsers, ids, "User", loadMode, loadAsync)
    }

    function getUserList(ids: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        return getAsyncUserList(ids, loadMode).map(a => a.data).filter(isDefined)
    }

    async function getUserListAsync(ids: (string | undefined)[]) {
        return (await Promise.all(getAsyncUserList(ids, LoadMode.EnsureLoaded).map(a => a.whenLoadCompleted)))
            .filter(isDefined)
    }

    async function searchUsersAsync(name?: string, pageSize = 100, nextPageToken?: string) {
        const requestTime = DateTime.utc()
        const q = name ? `name:"${name}"` : undefined
        const plainPage = await gp.getPlainAsync("users", { q, pageSize, next: nextPageToken, expand: 'items' }) as PaginatedList
        console.log(`Loaded page of ${plainPage.items.length} users`)
        const users = addUsersToStore(plainPage.items, requestTime)
        return { items: [...users], nextPageToken: plainPage.nextPageToken }
    }

    function getAsyncPermissions(id: string | undefined, loadMode = LoadMode.TrackOnly) {
        return getAsyncPermissionsCore(asyncPermissions, id, "User", "users", loadMode)
    }

    async function getPermissionsAsync(id: string | undefined) {
        return (await getAsyncPermissions(id, LoadMode.EnsureLoaded)?.whenLoadCompleted) ?? ItemPermissions.None
    }

    async function getPrivilegesAsync(id: string) {
        const p = await gp.getPlainAsync(`users/${id}/privileges`)
        return (p as string).split(',').map(s => s.trim()) as UserPrivilege[]
    }

    async function addForProfileAsync(profileGroupId: string) {
        const addCoreAsync = async () => {
            const p = await gp.postPlainAsync(`users`, undefined, { profile: profileGroupId })
            return plainToUser(p)
        }            
        return await AsyncDataStore.addToStoreMapAsync(asyncUsers, "User", u => u.id, addCoreAsync)
    }
    
    async function patchAsync(id: string, changes: PatchChange[]) {
        checkNoAlias(id)
        const patchUserAsync = async (id: string, changes: PatchChange[]) => {
            const p = await gp.patchPlainAsync(`users/${id}`, changes);
            return plainToUser(p);
        }
        await AsyncDataStore.patchFromStoreAsync(asyncUsers, id, "User", changes, patchUserAsync)
    }

    async function setLimitsDisabledAsync(id: string, value: boolean) {
        await gp.postPlainAsync(`users/${id}/limitsDisabled`, undefined, { value })
        getAsyncUser(id, LoadMode.Reload) // trigger reload, but don't wait for it
    }
    
    async function resetAsync(id: string) {
        await gp.postPlainAsync(`users/${id}/reset`)
        getAsyncUser(id, LoadMode.Reload) // trigger reload, but don't wait for it
    }

    function addUsersToStore(plainUsers: any[], requestTime: DateTime) {
        const userMap = processExpandedUsers(plainUsers, requestTime)
        AsyncDataStore.addItemMapToStore(asyncUsers, "User", userMap, requestTime)
        return userMap.values()
    }

    function checkNoAlias(id: string | undefined) {
        // use of aliases could result in multiple (possibly inconsistent) copies of a item in the store under different keys
        if (id == "$me") {
            throw 'UserStore.AliasNotAllowed';
        }
    }

    function plainToUser(p: any) {
        const user = assignExisting(new User(), p)
        user.limitsDisabled = p.limitsDisabled ?? false
        user.limits = assignExisting(new UserLimits(), p.limits)
        user.createdDate = DateTimeUtil.fromAPI(p.createdDate)
        user.modifiedDate = DateTimeUtil.fromAPI(p.modifiedDate)
        user.metadata = p.metadata ? assignExisting(new UserMetadata(), p.metadata) : undefined
        return user
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function processExpandedUsers(plainUsers: any[], requestTime: DateTime) {
        return AsyncDataStore.createItemMap(plainUsers, "User", plainToUser, u => u.id!)
    }
    return {
        asyncUsers,
        asyncDataGroupMemberKeys,
        asyncFamilyGroupMemberKeys,
        asyncFamilyGroupInviteKeys,
        asyncSubscriptionKeys,
        asyncPaymentMethodKeys,
        asyncSupervisorKeys,
        asyncSupervisedUserKeys,
        asyncPermissions,
        getAsyncSelf,
        getAsyncUser,
        getSelfPersonIdAsync,
        getAsyncUserList,
        getUserList,
        getUserListAsync,
        searchUsersAsync,
        getAsyncPermissions,
        getPermissionsAsync,
        getPrivilegesAsync,
        addForProfileAsync,
        patchAsync,
        setLimitsDisabledAsync,
        resetAsync,
        addUsersToStore,
    }
})
