import { useGroupAdminApi } from './GroupAdminApi'
import { ItemPermissions } from './GroupAdminModel'
import { isDefined } from '@webapp/util/TypeScriptUtil'
import { AsyncData, LoadMode } from '@webapp/util/AsyncData'
import { AsyncDataStore } from '@webapp/util/AsyncDataStore'

const nameToPermissions = new Map<string, ItemPermissions>(Object.entries({
    Read: ItemPermissions.Read,
    ReadContent: ItemPermissions.ReadContent,
    AddContent: ItemPermissions.AddContent,
    ModifyContent: ItemPermissions.ModifyContent,
    ListItems: ItemPermissions.ListItems,
    ReadItems: ItemPermissions.ReadItems,
    ModifyItems: ItemPermissions.ModifyItems,
    Modify: ItemPermissions.Modify,
    AddItems: ItemPermissions.AddItems,
    DeleteItems: ItemPermissions.DeleteItems,
    Delete: ItemPermissions.Delete,
    ReadPermissions: ItemPermissions.ReadPermissions,
    ModifyPermissions: ItemPermissions.ModifyPermissions,
    Owner: ItemPermissions.Owner,
}))

export function listToItemPermissions(list: string | undefined) {
    return (list?.split(",") ?? [])
        .map(p => p.trim())
        .map(p => nameToPermissions.get(p))
        .filter(isDefined)
        .reduce((prev, curr) => prev |= curr, ItemPermissions.None)
}

/* eslint-disable @typescript-eslint/no-explicit-any -- expected when working with plain objects returned from API's */
/* eslint-disable @typescript-eslint/no-non-null-assertion -- expected when working with properties always set by server (e.g. "id") */

export function getAsyncPermissionsCore(storeMap: Map<string, AsyncData<ItemPermissions>>, id: string | undefined, typeName: string, itemPath: string, loadMode: LoadMode) {
    if (!id)
        return undefined

    const gp = useGroupAdminApi()

    const loadAsync = async () => {
        const plainPerms = await gp.getPlainAsync(`${itemPath}/${id}/permissions`)
        console.log(`Loaded permissions for ${typeName} ${id}`)
        const perms = listToItemPermissions(plainPerms as string | undefined)
        return new Map<string, ItemPermissions>([[id!, perms]])
    }
    return AsyncDataStore.getAsyncItemsByIds(storeMap, [id], `${typeName}.Permissions`, loadMode, loadAsync)[0]
}

export function getAsyncPermissionsListCore(storeMap: Map<string, AsyncData<ItemPermissions>>, ids: string[], typeName: string, itemPath: string, loadMode: LoadMode) {
    const gp = useGroupAdminApi()
    
    const loadListAsync = async () => {
        const plainPermsList = (await gp.getPlainByIdsAsync(id => `${itemPath}/${id}/permissions`, ids, undefined, true)) as string[]
        console.log(`Loaded permissions for ${ids.length} ${typeName}s`)
        const permsList = plainPermsList.map((p, i) => [ids[i], listToItemPermissions(p)]) as Iterable<[string, ItemPermissions]>
        return new Map<string, ItemPermissions>(permsList)
    }
    return AsyncDataStore.getAsyncItemsByIds(storeMap, ids, `${typeName}.Permissions`, loadMode, loadListAsync)
}
