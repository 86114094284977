<template>
  <Modal ref="modalRef" :ok-style="okStyleRef" :ok-disabled="!enableAddRef" @ok="addSupervisor">
    <template #title>
      Add parent account
    </template>
    <p v-if="isFirstSupervisorRef">
      To make this a child account, select a parent to manage it.
    </p>
    <p v-if="isFirstSupervisorRef" class="help-text">
      When you add a parent account,
      the child account will no longer be able to add to or change their personal information,
      and some features of the site will be disabled.
      The parent account will have full control of the child account.
    </p>
    <p v-else class="help-text">
      Each parent account will have full control over the child account.
    </p>
    <p>
      Select a parent user:
    </p>
    <div>
      <UserItem v-if="supervisorIdRef" :user-id="supervisorIdRef" select-mode @click="clearSelected" />
      <UserSelector v-else @user-selected="onSupervisorSelected"/>
    </div>
    <template #okText>Add parent</template>
  </Modal>
</template>

<style lang="scss" scoped>
.user-item {
  margin: 1rem;
}

.user-selector {
  margin: 1rem 0;
}
</style>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useUserSupervisorStore } from '@/gp/UserSupervisorStore'
import { UserSupervisor } from '@/gp/GroupAdminModel'
import { useTokenManager } from '@/auth/TokenManager'
import { LoadMode } from '@/util/AsyncData'
import Modal from '@/util/Modal.vue'
import UserSelector from './UserSelector.vue'
import UserItem from './UserItem.vue'

const supervisorIdRef = ref<string>()
const modalRef = ref<InstanceType<typeof Modal>>()

const userSupervisorStore = useUserSupervisorStore()
const tokenManager = useTokenManager()

defineExpose({
  open
})

const forUserIdRef = ref<string>()
const enableAddRef = computed(() => !!supervisorIdRef.value)
const supervisorsRef = computed(() => userSupervisorStore.getForSupervisedUser(forUserIdRef.value, LoadMode.EnsureLoaded))
const isFirstSupervisorRef = computed(() => supervisorsRef.value.length == 0)
const okStyleRef = computed(() => isFirstSupervisorRef.value ? "danger" : undefined)

function open(forUserId: string) {
  forUserIdRef.value = forUserId
  modalRef.value?.open()
}

function onSupervisorSelected(userId: string) {
  supervisorIdRef.value = userId
}

function clearSelected() {
  supervisorIdRef.value = undefined
}

async function addSupervisor() {
  const isFirst = isFirstSupervisorRef.value

  const sup = new UserSupervisor()
  sup.supervisorId = supervisorIdRef.value
  sup.forUserId = forUserIdRef.value
  await userSupervisorStore.addAsync(sup)

  if (isFirst) {
    await tokenManager.refreshAsync()
    window.location.reload()
  }
  else {
    modalRef.value?.close()
  }
}

</script>
