<template>
  <div>
    <h1>Signing out...</h1>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { usePageTitle } from '@/util/AppUtil'
import { useTokenManager } from "@/auth/TokenManager"

usePageTitle("Signing out")

const tokenManager = useTokenManager()

onMounted(() => {
    tokenManager.clearToken();
    window.location.href = "/";
  }
)
</script>