import { reactive } from 'vue'
import { Person, PersonDisplayProperties } from "./ResearchDataModel"
import { useAssertionStore } from './AssertionStore'
import { useResearchDataApi } from './ResearchDataApi'
import { AsyncData, LoadMode } from "@webapp/util/AsyncData"
import { AsyncDataStore, VersionedKeyList } from "@webapp/util/AsyncDataStore"
import { defineStore } from "pinia"
import { DateTime } from "luxon"
import { DateTimeUtil } from "@webapp/util/LuxonUtil"
import _ from "lodash"
import { assignExisting, isDefined } from "@webapp/util/TypeScriptUtil"
import { HistoricalDate } from './HistoricalDate'
import { SortDate } from './SortDate'
import { usePlaceStore } from './PlaceStore'
import { plainToSignedUrl } from './ContentStore'
import { useContentTagStore } from './ContentTagStore'

const personExpand = "displayProperties"

export const usePersonStore = defineStore("personStore", () => {
    const asyncPersons = reactive(new Map<string, AsyncData<Person>>())
    const asyncAssertionKeys = reactive(new Map<string, AsyncData<VersionedKeyList>>())
    const asyncContentTagKeys = reactive(new Map<string, AsyncData<VersionedKeyList>>())

    const rd = useResearchDataApi()
    const assertionStore = useAssertionStore()
    const placeStore = usePlaceStore()
    const contentTagStore = useContentTagStore()

    function getAsyncPerson(id: string | undefined, loadMode = LoadMode.TrackOnly) {
        return id ? getAsyncPersonList([id], loadMode)[0] : undefined
    }

    function getAsyncPersonList(ids: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const loadPersonsAsync = async (idsToLoad: string[], requestTime: DateTime) => {
            const plainPersons = await rd.getPlainByIdsAsync("persons", idsToLoad, personExpand)
            return processExpandedPersons(plainPersons, requestTime)
        }
        return AsyncDataStore.getAsyncItemsByIds(asyncPersons, ids, "Person", loadMode, loadPersonsAsync)
    }

    async function getPersonListAsync(ids: (string | undefined)[]) {
        const asyncPersons = getAsyncPersonList(ids, LoadMode.EnsureLoaded)
        return (await Promise.all(asyncPersons.map(a => a.whenLoadCompleted))).filter(isDefined)
    }

    function getAsyncAssertionKeyLists(personIds: string[], loadMode = LoadMode.TrackOnly) {
        if (loadMode != LoadMode.TrackOnly) {
            throw `Load mode not implemented`
        }
        // ensure all requested key lists are present
        AsyncDataStore.getAsyncItemsToLoad(asyncAssertionKeys, personIds, "Person.AssertionKeys", loadMode)
        // get all key lists (not just the ones that need loading)
        return personIds.map(id => asyncAssertionKeys.get(id)!)
    }

    function areDetailsLoaded(personId: string) {
        const p = asyncPersons.get(personId)
        if (!p || !p.loadComplete)
            return false

        const asyncAssertionKeyList = asyncAssertionKeys.get(personId)
        if (!asyncAssertionKeyList)
            return false

        const assertionIds = asyncAssertionKeyList.data?.keys ?? []
        return assertionStore.areExpandedAssertionsLoaded(assertionIds)
    }

    async function loadDetailsAsync(personId: string, loadMode = LoadMode.TrackOnly) {
        if (loadMode != LoadMode.Reload && areDetailsLoaded(personId)) {
            console.log(`Details for person ${personId} already loaded`)
            return
        }
        if (loadMode != LoadMode.TrackOnly) {
            const expand = "displayProperties,assertions/place,assertions/site"
            const requestTime = DateTime.utc()
            const plainPersons = await rd.getPlainByIdsAsync("persons", [personId], expand)

            processExpandedPersons(plainPersons, requestTime)
        }
    }

    async function addPlaceholderAsync(groupId: string) {
        const addCoreAsync = async () => {
            const p = await rd.postPlainAsync(`groups/${groupId}/persons`)
            return plainToPerson(p)
        }            
        return await AsyncDataStore.addToStoreMapAsync(asyncPersons, "Person", ps => ps.id, addCoreAsync)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function addPersonsToStore(plainPersons: any[], requestTime: DateTime) {
        const personMap = processExpandedPersons(plainPersons, requestTime)
        AsyncDataStore.addItemMapToStore(asyncPersons, "Person", personMap, requestTime)
    }

    function processExpandedPersons(plainPersons: any[], requestTime: DateTime) {
        processExpandedPersonDisplayProperties(plainPersons.map(p => p.displayProperties), requestTime)

        const assertionMap = new Map(plainPersons
            .filter(p => p.assertions) // only if assertion list is present
            .map(p => [p.id as string, p.assertions as any[]]))
        processExpandedPersonAssertions(assertionMap, requestTime)

        const tagMap = new Map(plainPersons
            .filter(p => p.contentTags)
            .map(p => [p.id as string, p.contentTags as any[]]))
        processExpandedPersonContentTags(tagMap, requestTime)
        
        return AsyncDataStore.createItemMap(plainPersons, "Person", plainToPerson, p => p.id!)
    }

    function processExpandedPersonDisplayProperties(plainDisplayProperties: any[], requestTime: DateTime) {
        placeStore.addPlacesToStore(plainDisplayProperties.map(p => p.birthPlace).filter(isDefined), requestTime)
        placeStore.addPlacesToStore(plainDisplayProperties.map(p => p.deathPlace).filter(isDefined), requestTime)
    }

    function processExpandedPersonAssertions(plainAssertionMap: Map<string, any[]>, requestTime: DateTime) {
        for (const e of plainAssertionMap.entries()) { // e = [personId, plainAssertions]
            const assertionMap = assertionStore.addAssertionsToStore(e[1], requestTime)
            const assertionIds = [...assertionMap.values()].map(a => a.id!)

            AsyncDataStore.addKeyListToStore(asyncAssertionKeys, e[0], "Person.AssertionKeys", assertionIds, requestTime)
        }
    }

    function processExpandedPersonContentTags(plainTagMap: Map<string, any[]>, requestTime: DateTime) {
        for (const e of plainTagMap.entries()) { // e = [personId, plainTags]
            const tagMap = contentTagStore.addTagsToStore(e[1], requestTime)
            const tagIds = [...tagMap.values()].map(t => t.id!)

            AsyncDataStore.addKeyListToStore(asyncContentTagKeys, e[0], "Person.ContentTagKeys", tagIds, requestTime)
        }
    }

    return {
        asyncPersons,
        asyncAssertionKeys,
        asyncContentTagKeys,

        getAsyncPerson,
        getAsyncPersonList,
        getPersonListAsync,
        getAsyncAssertionKeyLists,
        areDetailsLoaded,
        loadDetailsAsync,
        addPlaceholderAsync,

        addPersonsToStore,
        processExpandedPersons,
        processExpandedPersonDisplayProperties,
        processExpandedPersonAssertions,
        processExpandedPersonContentTags,
    }
})

function plainToPerson(p: any) {
    const person = assignExisting(new Person(), p)
    person.modifiedDate = DateTimeUtil.fromAPI(p.modifiedDate);
    person.displayProperties = plainToPersonDisplayProperties(p.displayProperties ?? {})
    return person
}

export function plainToPersonDisplayProperties(p: any) {
    const dp = assignExisting(new PersonDisplayProperties(), p)
    
    dp.birthDate = HistoricalDate.parse(p.birthDate)
    dp.birthSortDate = SortDate.parse(p.birthSortDate)
    dp.birthPlaceId = p.birthPlace?.id

    dp.deathDate = HistoricalDate.parse(p.deathDate)
    dp.deathSortDate = SortDate.parse(p.deathSortDate)
    dp.deathPlaceId = p.deathPlace?.id

    dp.primaryPhotoSignedUrl = plainToSignedUrl(p.primaryPhotoSignedUrl)
    return dp
}
