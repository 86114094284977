<template>
  <div>
    <h1>Signing in...</h1>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { usePageTitle } from '@/util/AppUtil'
import { useTokenManager } from '@/auth/TokenManager'

usePageTitle("Signing in")

const tokenManager = useTokenManager()

async function exchangeToken() {
  const urlParams = new URLSearchParams(window.location.search)
  const accessToken = urlParams.get('access_token')
  const clientId = urlParams.get('client_id')
  const code = urlParams.get('code')

  if (accessToken) {
    await tokenManager.exchangeToken(accessToken)
  }
  else if (clientId && code) {
    await tokenManager.getAccessTokenFromCodeAsync(clientId, code)
  }

  let url = urlParams.get('redirect_uri')
  if (!url?.startsWith("/")) {
    url = "/home"
  }

  // sign in complete, so navigate to target url
  window.location.href = url
}

onMounted(() => {
  exchangeToken()
})
</script>
