import { DateTime, Duration } from 'luxon'
import { AccessTokenResponse } from './AccessTokenResponse'
import { LocalTokenApi } from './LocalTokenApi'
import { AsyncUtil } from '@webapp/util/AsyncUtil'
import { DurationUtil } from '@webapp/util/LuxonUtil'
import { useTelemetry } from '@webapp/util/Telemetry'
import jwtDecode, { JwtPayload } from 'jwt-decode'

interface TokenPayload extends JwtPayload {
    scope?: string
    profile_id?: string
    is_supervised?: boolean
    act?: {
        sub: string
    }
}

const userIdStorageKey = 'tc-user-id';
const actorIdStorageKey = 'tc-actor-id';
const userProfileIdStorageKey = 'tc-user-profile-id'
const userPrivilegesStorageKey = 'tc-user-privileges'
const isSupervisedStorageKey = 'tc-is-supervised'

const accessTokenStorageKey = 'tc-access-token'
const accessTokenExpiresStorageKey = 'tc-access-token-expires'

const refreshTokenStorageKey = 'tc-refresh-token'
const refreshTokenExpiresStorageKey = 'tc-refresh-token-expires'

const identityTokenStorageKey = "tc-identity-token"
const identityTokenExpiresStorageKey = "tc-identity-token-expires"

const earlyRefreshInterval = DurationUtil.fromReadable('00:15:00')
const noTokenRefreshDelay = DurationUtil.fromReadable('00:05:00')
const refreshFailedDelay = DurationUtil.fromReadable('00:00:30')
const maxWaitForRefresh = DurationUtil.fromReadable('00:00:05')

function toExpirationDate(expireDateStr: string | null): DateTime {
    return DateTime.fromISO(expireDateStr ?? '2000-01-01')
}

export enum UserPrivilege {
    User = 'User',
    ReadAllUsers = 'ReadAllUsers',
    ReadAllGroups = 'ReadAllGroups',
    ImpersonateUser = 'ImpersonateUser',
    ManageUsers = 'ManageUsers',
    DemoUser = 'DemoUser',
}

export function getPrivilegeDisplayName(privilege: UserPrivilege) {
    switch (privilege) {
        case UserPrivilege.User: return 'Standard user'
        case UserPrivilege.ReadAllUsers: return 'Read all users'
        case UserPrivilege.ReadAllGroups: return 'Read all groups'
        case UserPrivilege.ManageUsers: return 'Manage users'
        case UserPrivilege.ImpersonateUser: return 'Impersonate user'
    }
}

export interface TokenInfo {
    token?: string
    expires?: DateTime
    isValid: boolean
}

export interface AccessTokenInfo extends TokenInfo {
    userId?: string
    actorId?: string
    userProfileId?: string
    userPrivileges: UserPrivilege[]
    isPrivilegedUser: boolean
    isSupervised: boolean
}

export interface RefreshTokenInfo extends TokenInfo {
    canRefresh: boolean
}

// HACK: This static class provides direct access to stored token and user info, without using the composable pattern.
// No good reason for this. Could refactor to use the pattern like everything else.
export class TokenManager {

    static get userId() { return window.localStorage.getItem(userIdStorageKey) ?? undefined } // null -> undefined
    static get actorId() { return window.localStorage.getItem(actorIdStorageKey) ?? undefined } // null -> undefined
    static get userProfileId() { return window.localStorage.getItem(userProfileIdStorageKey) ?? undefined } // null -> undefined
    static get userPrivileges() { return (window.localStorage.getItem(userPrivilegesStorageKey) ?? '').split(' ') as UserPrivilege[] }
    static get isPrivilegedUser() { return TokenManager.userPrivileges.some(p => p && p != UserPrivilege.User && p != UserPrivilege.DemoUser) }
    static get isSupervised() { return (window.localStorage.getItem(isSupervisedStorageKey) ?? '') == 'true' }
    static get accessToken() { return window.localStorage.getItem(accessTokenStorageKey) ?? undefined } // null -> undefined
    static get accessTokenExpires() { return toExpirationDate(window.localStorage.getItem(accessTokenExpiresStorageKey)) }
    static get refreshToken() { return window.localStorage.getItem(refreshTokenStorageKey) ?? undefined } // null -> undefined
    static get refreshTokenExpires() { return toExpirationDate(window.localStorage.getItem(refreshTokenExpiresStorageKey)) }
    static get identityToken() { return window.localStorage.getItem(identityTokenStorageKey) ?? undefined } // null -> undefined
    static get identityTokenExpires() { return toExpirationDate(window.localStorage.getItem(identityTokenExpiresStorageKey)) }

    /**
     * Returns true if an unexpired access token is present.
     */
    static get isAccessTokenValid() { return TokenManager.isTokenValid(TokenManager.accessToken, TokenManager.accessTokenExpires) }

    /**
     * Returns true if both an access token (expired okay) and a refresh token (unexpired) are present.
     */
    static get canRefresh() {
        return !!TokenManager.accessToken && !!TokenManager.refreshToken && TokenManager.refreshTokenExpires > DateTime.utc()
    }

    static isTokenValid(token: string | undefined, expires: DateTime) {
        return !!token && expires > DateTime.utc()
    }

    static hasPrivilege(privilege: UserPrivilege) {
        return TokenManager.userPrivileges.includes(privilege)
    }
}


export function useTokenManager() {

    const { trackEvent, trackException } = useTelemetry()

    const accessToken: AccessTokenInfo = {
        get token() { return TokenManager.accessToken },
        get expires() { return TokenManager.accessTokenExpires },
        get userId() { return TokenManager.userId },
        get actorId() { return TokenManager.actorId },
        get userProfileId() { return TokenManager.userProfileId },
        get userPrivileges() { return TokenManager.userPrivileges },
        get isPrivilegedUser() { return TokenManager.isPrivilegedUser },
        get isSupervised() { return TokenManager.isSupervised },
        get isValid() { return TokenManager.isAccessTokenValid },
    }

    const refreshToken: RefreshTokenInfo = {
        get token() { return TokenManager.refreshToken },
        get expires() { return TokenManager.refreshTokenExpires },
        get isValid() { return TokenManager.isTokenValid(TokenManager.refreshToken, TokenManager.refreshTokenExpires) },
        get canRefresh() { return TokenManager.canRefresh },
    }

    const identityToken: TokenInfo = {
        get token() { return TokenManager.identityToken },
        get expires() { return TokenManager.identityTokenExpires },
        get isValid() { return TokenManager.isTokenValid(TokenManager.identityToken, TokenManager.identityTokenExpires) },
    }

    function clearToken() {
        window.localStorage.removeItem(userIdStorageKey)
        window.localStorage.removeItem(userProfileIdStorageKey)
        window.localStorage.removeItem(userPrivilegesStorageKey)

        window.localStorage.removeItem(accessTokenStorageKey)
        window.localStorage.removeItem(accessTokenExpiresStorageKey)

        window.localStorage.removeItem(refreshTokenStorageKey)
        window.localStorage.removeItem(refreshTokenExpiresStorageKey)

        window.localStorage.removeItem(identityTokenStorageKey)
        window.localStorage.removeItem(identityTokenExpiresStorageKey)
    }

    function saveToken(response: AccessTokenResponse) {
        const expireDate = DateTime.utc().plus({ seconds: response.expires_in })
        const refreshExpireDate = DateTime.utc().plus({ seconds: response.refresh_token_expires_in })

        const tokenObj = jwtDecode<TokenPayload>(response.access_token)

        window.localStorage.setItem(userIdStorageKey, tokenObj.sub!) // eslint-disable-line @typescript-eslint/no-non-null-assertion
        window.localStorage.setItem(actorIdStorageKey, tokenObj.act?.sub ?? '')
        window.localStorage.setItem(userProfileIdStorageKey, tokenObj.profile_id ?? '')
        window.localStorage.setItem(userPrivilegesStorageKey, tokenObj.scope ?? '')
        window.localStorage.setItem(isSupervisedStorageKey, tokenObj.is_supervised ? 'true' : 'false')
        window.localStorage.setItem(accessTokenStorageKey, response.access_token)
        window.localStorage.setItem(accessTokenExpiresStorageKey, expireDate.toISO() ?? '')

        trackEvent("TokenManager.AccessTokenSaved", { 
            token: `...${response.access_token.slice(-5)}`, 
            expires: expireDate.toLocal().toLocaleString(DateTime.TIME_WITH_SECONDS)
        })

        const oldRefreshToken = window.localStorage.getItem(refreshTokenStorageKey)
        if (response.refresh_token !== oldRefreshToken) {
            window.localStorage.setItem(refreshTokenStorageKey, response.refresh_token)
            window.localStorage.setItem(refreshTokenExpiresStorageKey, refreshExpireDate.toISO() ?? '')

            trackEvent("TokenManager.RefreshTokenSaved", { 
                token: `...${response.refresh_token.slice(-5)}`, 
                expires: refreshExpireDate.toLocal().toLocaleString(DateTime.TIME_WITH_SECONDS)
            })
        }
    }

    /**
     * Gets a valid access token (if possible) for the current user, waiting a short time for token refresh if necessary.
     */
    async function getAccessTokenAsync() {
        if (TokenManager.isAccessTokenValid)
            return TokenManager.accessToken

        if (TokenManager.canRefresh) {
            // auto-refresh should be in progress, so just wait a bit for it to succeed

            // NOTE: Having an invalid but refreshable access token should only occur if app has been idle, and 
            // as a result auto-refresh was paused long enough for the current token to expire. Normally, refresh 
            // occurs well before the token expires, so a valid token is always available.

            const start = DateTime.utc()
            while (DateTime.utc().diff(start) < maxWaitForRefresh) {
                await AsyncUtil.delay(200) // try to respond ASAP
                if (TokenManager.isAccessTokenValid) {
                    return TokenManager.accessToken
                }
            }
        }
    }

    /**
     * Gets a token that can be used with the identity service to get identity information for the current user.
     */
    async function getIdentityTokenAsync() {
        if (TokenManager.accessToken) {
            const token = window.localStorage.getItem(identityTokenStorageKey)
            const tokenExpires = TokenManager.identityTokenExpires

            if (token && DateTime.utc() < tokenExpires.minus({ minutes: 5 })) {
                return token
            }
            else {
                const newToken = await LocalTokenApi.getLocalIdentityTokenAsync(TokenManager.accessToken)
                saveIdentityToken(newToken)
                return newToken.access_token
            }
        }
    }

    function saveIdentityToken(response: AccessTokenResponse) {
        const expireDate = DateTime.utc().plus({ seconds: response.expires_in })
        window.localStorage.setItem(identityTokenStorageKey, response.access_token)
        window.localStorage.setItem(identityTokenExpiresStorageKey, expireDate.toISO() ?? '')
                
        trackEvent('TokenManager.IdentityTokenSaved', {
            token: `...${response.access_token.slice(-5)}`,
            expires: expireDate.toLocal().toLocaleString(DateTime.TIME_WITH_SECONDS)
        })
    }

    async function exchangeToken(identityToken: string) {
        const response = await LocalTokenApi.getLocalAccessTokenAsync(identityToken)
        
        trackEvent('TokenManager.IdentityTokenExchanged')
        saveToken(response)
    }

    async function getAccessTokenFromCodeAsync(clientId: string, code: string) {
        const response = await LocalTokenApi.getLocalAccessTokenFromCodeAsync(clientId, code)
        trackEvent('TokenManager.AuthCodeUsed')
        saveToken(response)
    }

    async function impersonateAsync(userId: string) {
        if (!TokenManager.isAccessTokenValid)
            throw 'Cannot impersonate user without a valid access token'

        const actorId = TokenManager.userId // current user
        const response = await LocalTokenApi.getLocalAccessTokenForUserAsync(TokenManager.accessToken!, userId)

        trackEvent('TokenManager.UserImpersonated', { actorId, userId })
        saveToken(response)
    }

    async function ensureRefreshedAsync() {
        if (!TokenManager.isAccessTokenValid && TokenManager.canRefresh) {
            console.log('Access token expired, can refresh, refreshing now')
            await refreshAsync()
            return true
        }
        return false
    }

    async function refreshAsync() {
        const response = await LocalTokenApi.refreshAccessToken(TokenManager.accessToken!, TokenManager.refreshToken!)
        trackEvent('TokenManager.TokenRefreshed')
        saveToken(response)
    }

    async function autoRefresh(onRefresh?: () => void) {
        // this method returns a promise that never resolves

        // eslint-disable-next-line no-constant-condition
        while (true) {
            if (TokenManager.canRefresh) {

                // time to refresh?
                const remaining = TokenManager.accessTokenExpires.diffNow()
                const refreshDelay = remaining.minus(earlyRefreshInterval)
                if (refreshDelay > Duration.fromMillis(0)) {
                    // nope, not yet
                    console.log(`autoRefresh: Unexpired token present, waiting ${refreshDelay.toFormat('hh:mm:ss')} before refresh`)
                    await AsyncUtil.delay(refreshDelay.as('milliseconds'))
                    continue // restart loop (don't assume anything about token state after delay)
                }

                trackEvent('TokenManager.AutoRefreshAttempt', {
                    expiringToken: `...${TokenManager.accessToken!.slice(-5)}`,
                    refreshToken: `...${TokenManager.refreshToken!.slice(-5)}`,
                    expires: TokenManager.accessTokenExpires.toLocal().toLocaleString(DateTime.TIME_WITH_SECONDS),
                })

                try {
                    await refreshAsync()
                    onRefresh?.()

                    // sanity check
                    const newRemaining = TokenManager.accessTokenExpires.diffNow()
                    if (newRemaining < earlyRefreshInterval) {
                        trackEvent('TokenManager.AccessTokenLifetimeTooShort', {
                            expiresIn: newRemaining.toFormat('hh:mm:ss')
                        })
                        break
                    }
                } catch (error) {
                    trackException(error)
                    await AsyncUtil.delay(refreshFailedDelay.as('milliseconds'))
                }
            }
            else {
                console.log(`autoRefresh: No token to refresh, will check again in ${noTokenRefreshDelay.toFormat('hh:mm:ss')}`)
                const remaining = TokenManager.accessTokenExpires.diffNow()
                if (remaining < Duration.fromMillis(0)) {
                    clearToken() // cleanup just in case
                }
                await AsyncUtil.delay(noTokenRefreshDelay.as('milliseconds'))
            }
        }
    }

    return {
        accessToken,
        refreshToken,
        identityToken,

        clearToken,
        saveToken,
        getAccessTokenAsync,
        getIdentityTokenAsync,
        exchangeToken,
        getAccessTokenFromCodeAsync,
        impersonateAsync,
        ensureRefreshedAsync,
        refreshAsync,
        autoRefresh,
    }
}
