import { defineStore } from 'pinia'
import { reactive } from 'vue'
import { PaymentMethod } from '@webapp/gp/GroupAdminModel'
import { useGroupAdminApi } from './GroupAdminApi'
import { AsyncData, LoadMode } from '@webapp/util/AsyncData'
import { AsyncDataStore } from '@webapp/util/AsyncDataStore'
import { TokenManager } from '@webapp/auth/TokenManager'
import { DateTime } from 'luxon'
import { assignExisting, isDefined } from '@webapp/util/TypeScriptUtil'
import { DateTimeUtil } from '@/util/LuxonUtil'
import { useUserStore } from './UserStore'
import { addRedactRule } from '@/util/RedactRules'

export interface LocalPaymentMethodData {
    cardNumber: string
    expirationMonth: number
    expirationYear: number
}

addRedactRule({ pattern: new RegExp('^/paymentintents/[^/]+/confirm$'), params: ['clientSecret'] })

export const usePaymentMethodStore = defineStore('paymentMethods', () => {
    const asyncPaymentMethods = reactive(new Map<string, AsyncData<PaymentMethod>>())

    const gp = useGroupAdminApi()
    const userStore = useUserStore()

    function getLoadedPaymentMethod(id: string | undefined) {
        return asyncPaymentMethods.get(id ?? '')?.data
    }

    function getLoadedPaymentMethodList(ids: (string | undefined)[]) {
        return ids.map(id => asyncPaymentMethods.get(id ?? '')).map(a => a?.data).filter(isDefined)
    }

    function getAsyncKeyListForUser(userId: string | undefined, loadMode = LoadMode.TrackOnly) {
        const loadAsync = async (userId: string[], requestTime: DateTime) => {
            const path = `users/${userId}/paymentmethods`
            const plainSubs = await gp.getPlainCollectionAsync(path)
            console.log(`Loaded ${plainSubs.length} payment methods at ${path}`)
            return processExpandedPaymentMethods(plainSubs, requestTime)
        }
        return AsyncDataStore.getAsyncKeyListForRelatedItems(TokenManager.userId, "User", 
            userStore.asyncPaymentMethodKeys, asyncPaymentMethods, "PaymentMethod", loadMode, loadAsync)
    }

    function getAsyncKeyListForSelf(loadMode = LoadMode.TrackOnly) {
        // NOTE: use the actual user id, because we don't want $me as a cache key
        return getAsyncKeyListForUser(TokenManager.userId, loadMode)
    }

    async function confirmLocalPayment(data: LocalPaymentMethodData, intentId: string, clientSecret: string) {
        await gp.postPlainAsync(`paymentintents/${intentId}/confirm`, data, { clientSecret })
    }

    function addPaymentMethodsToStore(plainPaymentMethods: any[], requestTime: DateTime) {
        const paymentMethodMap = processExpandedPaymentMethods(plainPaymentMethods, requestTime)
        AsyncDataStore.addItemMapToStore(asyncPaymentMethods, "PaymentMethod", paymentMethodMap, requestTime)
        return paymentMethodMap.values()
    }

    function processExpandedPaymentMethods(plainItems: any[], requestTime: DateTime) {
        // no expansion supported (yet)
        return AsyncDataStore.createItemMap(plainItems, "PaymentMethod", plainToPaymentMethod, pm => pm.id!)
    }

    function plainToPaymentMethod(p: any) {
        const pm = assignExisting(new PaymentMethod(), p)
        pm.expirationDate = DateTimeUtil.fromAPI(p.expirationDate)
        pm.modifiedDate = DateTimeUtil.fromAPI(p.modifiedDate)
        return pm        
    }

    return {
        asyncPaymentMethods,

        getLoadedPaymentMethod,
        getLoadedPaymentMethodList,
        getAsyncKeyListForSelf,
        confirmLocalPayment,
        addPaymentMethodsToStore,
    }
})
