<template>
  <TaskPage class="app-select-page" :can-continue="canContinueRef" @action="nextPage">
    <template #title>Get started</template>
    <template #instruction>
      What software do you use to manage your tree?
    </template>
    <AppSupportGrid select-mode show-all v-model:os="signInStore.os" v-model:app="signInStore.app" />
    <template #secondaryAction>
      <label v-if="signInStore.otherApp">
        <input type="checkbox" v-model="otherAppSelectedRef"/>
        I use something else:
      </label>
      <button type="button" class="btn btn-inline btn-link" data-bs-toggle="modal" data-bs-target="#otherAppModal">
        <template v-if="signInStore.otherApp">{{ signInStore.otherApp }}</template>
        <template v-else>I use something else</template>
      </button>
    </template>
    <Modal id="otherAppModal" ref="otherAppModalRef" @ok="otherAppModalClosed">
      <template #title>What software do you use?</template>
      <div>
        <input type="text" class="form-control" v-model="signInStore.otherApp"/>
      </div>
    </Modal>
  </TaskPage>
</template>

<style lang="scss" scoped>
.app-select-page {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    max-width: 600px;
    min-height: 500px;
    margin-top: 4rem;
    border: 1px solid #ddd;
    border-radius: 1rem;
    padding: 1rem 2rem 2rem 2rem;
  }
}

.instruction {
  margin-bottom: 1.5rem;
  color: #888;
}

label {
  input[type="checkbox"] {
    margin-right: 0.25rem;
  }
  
  margin-right: 0.25rem;
}
</style>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useSignInStore } from '@webapp/auth/SignInStore'
import { useIdentityApi } from '@webapp/auth/IdentityApi'
import { isWorkspaceTypeSupportedOn } from '@webapp/rd/ResearchDataModel'
import TaskPage from '@webapp/util/TaskPage.vue'
import AppSupportGrid from '@webapp/public/AppSupportGrid.vue'
import Modal from '@webapp/util/Modal.vue'

const identityApi = useIdentityApi()
const signInStore = useSignInStore()
const router = useRouter()

const busyRef = ref(false)
const otherAppSelectedRef = computed({
  get: () => !!signInStore.app && signInStore.app == signInStore.otherApp,
  set: value => signInStore.app = (value ? signInStore.otherApp : '')
})

const canContinueRef = computed(() => !busyRef.value && !!signInStore.os && !!signInStore.app)

const otherAppModalRef = ref<InstanceType<typeof Modal>>()

function otherAppModalClosed() {
  otherAppSelectedRef.value = true
  otherAppModalRef.value?.close()
}

async function nextPage() {
  if (isWorkspaceTypeSupportedOn(signInStore.app, signInStore.os)) {
    busyRef.value = true
    try {
      await identityApi.sendSignInCodeAsync(signInStore.address)
      router.push('/signincode')
    }
    finally {
      busyRef.value = false
    }
  }
  else {
    router.push('/signupnotify')
  }
}
</script>
