<template>
  <TaskPage class="signin-page" :no-title="appHostStore.isEmbedded" no-back no-action>
    <template #title>Sign in</template>
    <div class="body">
      <div>
        <label for="addressInput" class="form-label">Email address</label>
        <input type="text" class="form-control" id="addressInput" ref="addressInputRef" :readonly="!canEditRef" v-model="signInStore.address"/>
      </div>
      <div>
        <button type="submit" class="btn btn-primary" :disabled="!canSendCodeRef" @click="requestCode">
          <Spinner v-if="busyRef"></Spinner>
          Send a sign in code
        </button>
      </div>
      <div class="other-actions">
        <button type="button" v-if="appHostStore.isEmbedded" class="btn btn-inline btn-link" @click="emit('useAuthCode')">
          I have a code from the website
        </button>
        <button type="button" v-if="appHostStore.isEmbedded" class="btn btn-inline btn-link" @click="emit('noAccount')">
          I don't have an account
        </button>
        <router-link v-else to="/signup">
          I don't have an account
        </router-link>
      </div>
    </div>
  </TaskPage>
</template>

<style lang="scss" scoped>
.body {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.signin-page.embedded .body {
  align-items: center;
}

#addressInput {
  min-width: 300px;
  max-width: 350px;
}

.other-actions {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  a, button.btn-link {
    font-size: 0.875rem;
  }
}
</style>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useAppHostStore } from '@webapp/AppHostStore'
import { getSafeRedirectUri, usePageTitle } from '@webapp/util/AppUtil' 
import { useSignInStore } from '@webapp/auth/SignInStore'
import { useIdentityApi } from '@webapp/auth/IdentityApi'
import { AddressUtil } from '@webapp/util/AddressUtil'
import TaskPage from '@webapp/util/TaskPage.vue'
import Spinner from '@webapp/util/Spinner.vue'

usePageTitle("Sign in")

const emit = defineEmits([
  'useAuthCode',
  'noAccount'
])

const identityApi = useIdentityApi()
const appHostStore = useAppHostStore()
const signInStore = useSignInStore()
const router = useRouter()

const canEditRef = computed(() => !busyRef.value)
const canSendCodeRef = computed(() => !busyRef.value && AddressUtil.isValidEmail(signInStore.address))
const busyRef = ref(false)

const addressInputRef = ref<HTMLInputElement>()

async function requestCode(e: Event) {
  e.preventDefault()
  busyRef.value = true
  
  await identityApi.sendSignInCodeAsync(signInStore.address)

  busyRef.value = false

  signInStore.isNewUser = false
  
  const redirectUri = getSafeRedirectUri()
  router.push({ path: "/signincode", query: { redirect_uri: redirectUri } })
}

onMounted(() => {
  addressInputRef.value!.focus()
  addressInputRef.value!.select()
})
</script>
