import { reactive } from 'vue'
import { ItemType, ScopeStatistics, TrackingLink, Workspace } from "./ResearchDataModel"
import { useResearchDataApi } from './ResearchDataApi'
import { AsyncData, LoadMode } from "@webapp/util/AsyncData"
import { AsyncDataStore, VersionedKeyList } from "@webapp/util/AsyncDataStore"
import { defineStore } from "pinia"
import { DateTime } from "luxon"
import { DateTimeUtil } from "@webapp/util/LuxonUtil"
import { groupBy } from "lodash"
import { assignExisting, isDefined } from "@webapp/util/TypeScriptUtil"
import { listToItemPermissions } from '@webapp/gp/Permissions'
import { ItemPermissions } from '@webapp/gp/GroupAdminModel'
import { useGroupStore } from './GroupStore'
import { useSyncStore } from './SyncStore'


export const useTrackingLinkStore = defineStore("trackingLinkStore", () => {
    // NOTE: only person links for now
    const asyncTrackingLinks = reactive(new Map<ItemType, Map<string, AsyncData<TrackingLink>>>([[ItemType.Person, new Map<string, AsyncData<TrackingLink>>()]]))
    
    const rd = useResearchDataApi()

    function getAsyncTrackingLink(itemType: ItemType, itemId: string | undefined, loadMode = LoadMode.TrackOnly) {
        return getAsyncTrackingLinkList(itemType, [itemId], loadMode)[0]
    }
    
    function getAsyncTrackingLinkList(itemType: ItemType, itemIds: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        if (itemType != ItemType.Person) {
            throw `${itemType} tracking links are not supported`
        }
        const itemTypePath = "persons"
        const loadAsync = async (idsToLoad: string[], requestTime: DateTime) => {
            const plainItems = await rd.getPlainByIdsAsync(id => `${itemTypePath}/${id}/tracking`, idsToLoad)
            console.log(`Loaded ${plainItems.length} tracking links by id`)
            return processExpandedTrackingLinks(plainItems, requestTime)
        }
        const storeMap = asyncTrackingLinks.get(itemType)!
        return AsyncDataStore.getAsyncItemsByIds(storeMap, itemIds, "TrackingLink", loadMode, loadAsync)
    }

    async function getTrackingLinksForTrackedItemAsync(itemType: ItemType, itemId: string | undefined) {
        if (itemType != ItemType.Person) {
            throw `${itemType} tracking links are not supported`
        }
        const itemTypePath = "persons"
        const path = `${itemTypePath}/${itemId}/trackedby`
        const requestTime = DateTime.utc()

        const plainItems = await rd.getPlainCollectionAsync(path)
        console.log(`Loaded ${plainItems.length} tracking links at ${path}`)
        const itemMap = processExpandedTrackingLinks(plainItems, requestTime)
        const storeMap = asyncTrackingLinks.get(itemType)!
        AsyncDataStore.addItemMapToStore(storeMap, "TrackingLink", itemMap, requestTime)
        return [...itemMap.values()]
    }

    return {
        asyncTrackingLinks,

        getAsyncTrackingLink,
        getAsyncTrackingLinkList,
        getTrackingLinksForTrackedItemAsync,
    }
})

function plainToTrackingLink(p: any) {
    const t = assignExisting(new TrackingLink(), p)
    t.createdDate = DateTimeUtil.fromAPI(p.createdDate)
    return t;
}

function processExpandedTrackingLinks(plainLinks: any[], requestTime: DateTime) {
    return AsyncDataStore.createItemMap(plainLinks, "TrackingLink", plainToTrackingLink, t => t.itemId!)
}