import { reactive } from 'vue'
import { ContentTag, ItemType } from "./ResearchDataModel"
import { AsyncData, LoadMode } from "@webapp/util/AsyncData"
import { AsyncDataStore, VersionedKeyList } from "@webapp/util/AsyncDataStore"
import { defineStore } from "pinia"
import { DateTime } from "luxon"
import { DateTimeUtil } from "@webapp/util/LuxonUtil"
import { assignExisting, isDefined } from "@webapp/util/TypeScriptUtil"
import { useResearchDataApi } from './ResearchDataApi'
import { usePersonStore } from './PersonStore'
import { useContentStore } from './ContentStore'


const tagExpand = 'content/dataSignedUrls'

export const useContentTagStore = defineStore("contentTagStore", () => {
    const asyncContentTags = reactive(new Map<string, AsyncData<ContentTag>>())

    const rd = useResearchDataApi()
    const contentStore = useContentStore()
    const personStore = usePersonStore()

    function getLoadedTags(ids: (string | undefined)[]) {
        return ids.filter(isDefined).map(id => asyncContentTags.get(id)?.data).filter(isDefined)
    }

    function trackAsyncTag(tagId: string | undefined) {
        return tagId ? trackAsyncTags([tagId])[0] : undefined
    }

    function trackAsyncTags(tagIds: string[]) {
        AsyncDataStore.getAsyncItemsToLoad(asyncContentTags, tagIds, "ContentTag", LoadMode.TrackOnly)
        return tagIds.map(id => asyncContentTags.get(id)!)
    }
    
    function getAsyncKeyListsForItems(itemType: ItemType, itemIds: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        if (itemType != ItemType.Person)
            throw new Error(`Unsupported item type: ${itemType}`)

        const itemTypeName = 'Person'
        const itemTypePath = 'persons'
        const itemTypeAsyncKeyLists = personStore.asyncContentTagKeys

        const loadAsync = async (sourceKeys: string[], requestTime: DateTime) => {
            const ids = sourceKeys.filter(isDefined)
            const path = `${itemTypePath}/${sourceKeys.length == 1 ? sourceKeys[0] : '$list'}/contentTags`
            const query = { ids: sourceKeys.length == 1 ? undefined : ids.join() }
            const plainItems = await rd.getPlainCollectionAsync(path, tagExpand, query)
            console.log(`Loaded ${plainItems.length} content tags at ${path}`)
            return processExpandedContentTags(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncKeyListsForRelatedItems(itemIds, itemTypeName, itemTypeAsyncKeyLists,
            asyncContentTags, "ContentTag", loadMode, loadAsync, t => t.itemId!)
    }

    function getTagsForItems(itemType: ItemType, itemIds: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const keys = getAsyncKeyListsForItems(itemType, itemIds, loadMode).flatMap(a => a?.data?.keys ?? [])
        return getLoadedTags(keys)
    }
    
    async function getTagsForItemsAsync(itemType: ItemType, itemIds: (string | undefined)[]) {
        const keyListPromises = getAsyncKeyListsForItems(itemType, itemIds, LoadMode.EnsureLoaded).map(a => a.whenLoadCompleted)
        const keyLists = await Promise.all(keyListPromises)
        const keys = keyLists.filter(isDefined).flatMap(list => list.keys)
        return getLoadedTags(keys)
    }

    async function addAsync(tag: ContentTag) {
        const addCoreAsync = async () => {
            const p = await rd.postPlainAsync('contentTags', tag)
            return plainToTag(p)
        }            
        const id = await AsyncDataStore.addToStoreMapAsync(asyncContentTags, "ContentTag", t => t.id, addCoreAsync)
        const newTag = asyncContentTags.get(id)?.data // should be present

        invalidateKeyLists(newTag)
        return id
    }

    function addTagsToStore(plainTags: any[], requestTime: DateTime) {
        const tagMap = processExpandedContentTags(plainTags, requestTime)
        AsyncDataStore.addItemMapToStore(asyncContentTags, "ContentTag", tagMap, requestTime)
        return tagMap
    }

    function processExpandedContentTags(plainTags: any[], requestTime: DateTime) {
        contentStore.addContentsToStore(plainTags.map(t => t.content).filter(isDefined), requestTime)
        return AsyncDataStore.createItemMap(plainTags, "ContentTag", plainToTag, t => t.id!)
    }

    function invalidateKeyLists(tag: ContentTag | undefined) {
        if (!tag)
            return

        const asyncKeys = getItemKeyList(tag.itemType, tag.itemId!)
        AsyncData.invalidate(asyncKeys)
    }

    function getItemKeyList(itemType: ItemType, itemId: string) {
        if (itemType != ItemType.Person)
            throw new Error(`Unsupported item type: ${itemType}`)

        return personStore.asyncContentTagKeys.get(itemId)
    }

    return {
        asyncContents: asyncContentTags,

        getLoadedTags,
        trackAsyncTag,
        trackAsyncTags,
        getAsyncKeyListsForItems,
        getTagsForItems,
        getTagsForItemsAsync,
        addAsync,
        addTagsToStore,
    }
})

function plainToTag(p: any) {
    const tag = assignExisting(new ContentTag(), p)
    tag.itemType ??= ItemType.Person
    tag.isPrimary ??= false
    tag.profile ??= false
    tag.modifiedDate = DateTimeUtil.fromAPI(p.modifiedDate)
    tag.createdDate = DateTimeUtil.fromAPI(p.createdDate)
    return tag
}
