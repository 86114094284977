<template>
  <div class="user-page">
    <div class="user-header">
      <img :src="userImageRef"/>
      <div class="user-details">
        <h2>
          {{ userRef?.displayName }}
          <div v-if="isDemoUserRef" class="badge">
            DEMO
          </div>
        </h2>
        <div class="user-detail">
          <i class="fi fi-us"></i>{{ userLocationRef }}
        </div>
        <div class="user-detail">
          <CalendarIcon size="1.2x"/>Joined {{ userCreatedDateRef }}
        </div>
        <div class="contact-info">
          <!-- <button type="button" class="btn btn-inline btn-link">Contact me</button> -->
          <a v-if="getSocialLinkFor('x')" :href="getSocialLinkFor('x')" class="social-link">
            <img :src="socialAccountProviders.find(p => p.id == 'x')?.icon"/>
          </a>
          <a v-if="getSocialLinkFor('facebook')" :href="getSocialLinkFor('facebook')" class="social-link">
            <img :src="socialAccountProviders.find(p => p.id == 'facebook')?.icon"/>
          </a>
          <a v-if="showEmailRef" :href="`mailto:${userRef?.email}`" class="social-link">
            <MailIcon size="1x"/>
          </a>
          <a v-if="showPhoneRef" :href="`tel:${userRef?.mobileNumber}`" class="social-link">
            <PhoneIcon size="1x"/>
          </a>
        </div>
      </div>
      <div class="page-actions">
        <router-link v-if="!isSelfRef" :to="`${userRef?.pageUrl}/account`" class="btn btn-primary btn-sm">
          View account
        </router-link>
      </div>
    </div>
    <div>
      <ul class="nav nav-pills">
        <li class="nav-item">
          <button type="button" id="aboutTab" class="nav-link active" data-bs-toggle="tab" data-bs-target="#aboutPane" role="tab" aria-controls="aboutPane" aria-selected="false">
            About
          </button>
        </li>
        <li class="nav-item">
          <button type="button" id="researchGroupsTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#researchGroupsPane" role="tab" aria-controls="researchGroupsPane" aria-selected="true">
            Trees
          </button>
        </li>
        <li class="nav-item">
          <button type="button" id="familyGroupsTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#familyGroupsPane" role="tab" aria-controls="familyGroupsPane" aria-selected="true">
            Groups
          </button>
        </li>
      </ul>
    </div>
    <div class="tab-content content-area">
      <section id="aboutPane" class="tab-pane show active" role="tabpanel" aria-labelledby="aboutTab" tabindex="0">
        <UserProfileContent :user-id="userIdRef"/>
      </section>
      <section id="researchGroupsPane" class="tab-pane" role="tabpanel" aria-labelledby="researchGroupsTab" tabindex="0">
        <ResearchGroupList :user-id="userIdRef" for-user-profile/>
      </section>
      <section id="familyGroupsPane" class="tab-pane" role="tabpanel" aria-labelledby="familyGroupsTab" tabindex="0">
        <FamilyGroupList :user-id="userIdRef" for-user-profile/>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-header {
  margin-top: 0.5rem;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    width: 100px;
    border-radius: 50%;
    border: 2px solid white;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    background-color: #ccc;
    padding: 0.75rem;
    opacity: 70%;
  }

  .badge {
    max-height: 1.5rem;
    transform: translateY(-20%);
    padding: 0.25rem 0.5rem;
    font-size: 1rem;
    background-color: var(--bs-info);
    color: white;
  }  

  .user-details {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .user-detail {
    font-size: 0.875rem;
    color: #888;
    display: grid;
    grid-template-columns: 1.5rem auto;
    align-items: center;
  }

  .contact-info {
    display: inline-flex;
    gap: 4px;
    margin-top: 0.5rem;
    font-size: 0.875rem;

    button {
      margin-right: 1rem;
    }

    .social-link {
      padding: 0 2px;
    }

    img, svg {
      vertical-align: baseline;
      color: #888;
    }

    img {
      width: 0.85rem;
      filter: grayscale(100%);
      opacity: 60%;
    }

  }

  .page-actions {
    margin-top: 1rem;
  }

  @media(min-width: 768px) {
    flex-direction: row;
    gap: 1rem;

    .user-details {
      align-items: flex-start;
    }

    .page-actions {
      margin-left: auto;
      align-self: flex-end;
    }
  }
}

</style>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { usePageTitle } from '@/util/AppUtil'
import { useInitialTabRouting } from '@/util/InitialTabRouting'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useUserStore } from '@/gp/UserStore'
import { usePersonStore } from '@/rd/PersonStore'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { DataGroup, ItemPermissions, PrivacyLevel, socialAccountProviders } from '@/gp/GroupAdminModel'
import { LoadMode } from '@/util/AsyncData'
import { TokenManager, UserPrivilege } from '@/auth/TokenManager'
import { CalendarIcon, MailIcon, PhoneIcon } from '@zhuowenli/vue-feather-icons'
import FamilyGroupList from '@/manage/familygroups/FamilyGroupList.vue'
import ResearchGroupList from '@/manage/researchgroups/ResearchGroupList.vue'
import UserProfileContent from '@/manage/users/UserProfileContent.vue'

const props = defineProps<{
  userId?: string
}>();

const userProfileInViewRef = ref(false)
const userProfileGroupRef = ref<DataGroup>()
const userPersonIdRef = ref<string>()
const userPrivilegesRef = ref<UserPrivilege[]>()

const userStore = useUserStore()
const viewPersonStore = useViewPersonStore()
const dataGroupStore = useDataGroupStore()
const personStore = usePersonStore()

const userIdRef = computed(() => props.userId ?? TokenManager.userId!)
const isSelfRef = computed(() => userIdRef.value == TokenManager.userId)
const userRef = computed(() => userStore.getAsyncUser(userIdRef.value, LoadMode.EnsureLoaded)?.data)
const permissionsRef = computed(() => userStore.getAsyncPermissions(userIdRef.value, LoadMode.EnsureLoaded)?.data)
const isUserAdminRef = computed(() => permissionsRef.value && (permissionsRef.value & ItemPermissions.Modify) > 0)
const isDemoUserRef = computed(() => userPrivilegesRef.value?.includes(UserPrivilege.DemoUser))

const userImageRef = computed(() => userRef.value?.photoUrl ?? new URL('@/assets/icons/person_gray_150.png', import.meta.url).href)
const userLocationRef = computed(() => 'Anytown, State, USA')
const userCreatedDateRef = computed(() => 'April 2025')
const showEmailRef = computed(() => userRef.value?.email && userRef.value?.emailPrivacy != PrivacyLevel.Me)
const showPhoneRef = computed(() => userRef.value?.mobileNumber && userRef.value?.mobileNumberPrivacy != PrivacyLevel.Me)

const title = usePageTitle(() => isSelfRef.value ? "My account" : "User", () => isSelfRef.value ? undefined : userRef.value?.displayName, true)
useInitialTabRouting('aboutTab')

function getSocialLinkFor(providerId: string) {
  return userRef.value?.metadata?.getSocialAccountUrlFor(providerId)
}

watch(userRef, async () => {
  userProfileInViewRef.value = false
  userProfileGroupRef.value = undefined
  userPersonIdRef.value = undefined
  userPrivilegesRef.value = undefined

  if (userRef.value) {
    const selfViewGroups = await dataGroupStore.getViewGroupsForUserAsync()
    userProfileInViewRef.value = selfViewGroups.some(g => g.id == userRef.value?.profileDataGroupId)

    userPrivilegesRef.value = isSelfRef.value
      ? TokenManager.userPrivileges
      : await userStore.getPrivilegesAsync(userIdRef.value)

    if (userProfileInViewRef.value) {
      userProfileGroupRef.value = await dataGroupStore.getAsyncGroup(userRef.value.profileDataGroupId, LoadMode.EnsureLoaded)!.whenLoadCompleted
      userPersonIdRef.value = userProfileGroupRef.value?.startItemId

      if (userProfileGroupRef.value) {        
        const vp = await viewPersonStore.getAsyncPerson(userPersonIdRef.value, LoadMode.EnsureLoaded)!.whenLoadCompleted
        await Promise.all([
          personStore.getPersonListAsync(vp?.matchIds ?? []),
          dataGroupStore.getGroupListAsync(vp?.groupIds ?? []),
        ])
      }
    }
  }
}, { immediate: true })

</script>
