import './env' // DO THIS FIRST!

import { createApp } from 'vue'
import { RouteLocationNormalized } from 'vue-router'
import { createPinia } from 'pinia'
import { Environment } from './util/Environment'
import { AppInsightsPlugin, AppInsightsPluginOptions } from 'vue3-application-insights'
import { redact } from './util/RedactRules'
import { generateW3CId } from "@microsoft/applicationinsights-core-js"
import App from './App.vue'
import router from './router'
import 'bootstrap'
import './main.scss'

const appName = 'webapp'

const appInsightsOptions: AppInsightsPluginOptions = {
    connectionString: Environment.get('APPINSIGHTS_CS'),
    appName,
    trackAppErrors: true,
    onLoaded: (appInsights) => {
        // adapted from setupPageTracking function in vue3-application-insights
        const getRouteName = (route: RouteLocationNormalized) => {
            return route.name
                ? `.${route.name as string}` // use '.' delimiter for named routes
                : route.matched.at(-1)!.path // paths always start with '/' delimiter
        }
        router.beforeEach((route) => {
            const routeName = getRouteName(route)
            appInsights.context.telemetryTrace.traceID = generateW3CId()
            appInsights.context.telemetryTrace.name = routeName
            appInsights.startTrackPage(`${appName}${routeName}`)
        })
        router.afterEach((route) => {
            const routeName = getRouteName(route)
            const url = new URL(location.protocol + '//' + location.host + route.fullPath)
            if (route.meta.redact) {
                for (const p of route.meta.redact as string[]) {
                    if (url.searchParams.has(p)) {
                        url.searchParams.set(p, 'REDACTED')
                    }
                }                
            }
            appInsights.stopTrackPage(`${appName}${routeName}`, url.toString())
        })
        
        appInsights.addTelemetryInitializer(item => {
            const consoleLogObj: any = {}
            if (item.baseType == 'PageviewData' || item.baseType == 'EventData') {
                consoleLogObj.name = item.baseData?.name
                Object.assign(consoleLogObj, item.baseData?.properties)
            }
            else if (item.baseType == 'RemoteDependencyData') {
                consoleLogObj.name = item.baseData?.name
                consoleLogObj.success = item.baseData?.success
                consoleLogObj.duration = item.baseData?.duration
                consoleLogObj.responseCode = item.baseData?.responseCode
            }
            else if (item.baseType == 'ExceptionData') {
                consoleLogObj.message = item.baseData?.exceptions?.[0]?.message
            }
            Object.assign(consoleLogObj, item.data)
            console.log(`${item.baseType?.slice(0, -"Data".length)}: ${JSON.stringify(consoleLogObj)}`)
        })
        
        appInsights.addDependencyInitializer((dep) => redact(dep.item))
    },
}

createApp(App)
    .use(createPinia())
    .use(router)
    .use(AppInsightsPlugin, appInsightsOptions)
    .mount('#app')
