import { defineStore } from 'pinia'
import { DataGroupMember, DataGroupMemberKey, GroupMemberRole, PrincipalType } from './GroupAdminModel'
import { useGroupAdminApi } from './GroupAdminApi'
import { AsyncData, LoadMode } from '@webapp/util/AsyncData'
import { AsyncDataStore, VersionedKeyList } from '@webapp/util/AsyncDataStore'
import { useUserStore } from './UserStore'
import { useDataGroupStore } from './DataGroupStore'
import { useFamilyGroupStore } from './FamilyGroupStore'
import { DateTime } from 'luxon'
import { DateTimeUtil } from '@webapp/util/LuxonUtil'
import { assignExisting, isDefined } from '@webapp/util/TypeScriptUtil'
import { reactive, ref } from 'vue'
import { addRedactRule } from '@webapp/util/RedactRules'
import { TokenManager } from '@webapp/auth/TokenManager'

// SECURITY: don't log code parameter when loading data groups for a family group invite
addRedactRule({ pattern: new RegExp('^/familygroups/[^/]+/datagroups$'), params: ['code'] })

export const useDataGroupMemberStore = defineStore('dataGroupMembers', () => {
    const asyncDataGroupMembers = reactive(new Map<string, AsyncData<DataGroupMember>>())

    const gp = useGroupAdminApi()
    const dataGroupStore = useDataGroupStore()
    const userStore = useUserStore()
    const familyGroupStore = useFamilyGroupStore()

    function getLoadedMembers(keys: (string | DataGroupMemberKey | undefined)[]) {
        const ids = keys.filter(isDefined).map(key => typeof key == "string" ? key : DataGroupMember.toKeyString(key))
        return ids.map(id => asyncDataGroupMembers.get(id)?.data).filter(isDefined)
    }

    function getAsyncMemberByKey(key: string | DataGroupMemberKey, loadMode: LoadMode = LoadMode.TrackOnly) {
        // expand datagroup/owner, nothing else (this is typically used for reload after an add notification)
        const memberKey = typeof key == "string" ? DataGroupMember.parseKey(key) : key
        const loadAsync = async (ids: string[], requestTime: DateTime) => {
            const expand = "dataGroup/owner"
            const p = await gp.getPlainAsync(`datagroups/${memberKey.dataGroupId}/members/${memberKey.id}`, { expand })
            return processExpandedDataGroupMembers([p], requestTime)
        }
        const keyStr = DataGroupMember.toKeyString(memberKey)
        return AsyncDataStore.getAsyncItemsByIds(asyncDataGroupMembers, [keyStr], "DataGroupMember", loadMode, loadAsync)[0]
    }
        
    async function getMemberByPrincipalAsync(dataGroupId: string, type: PrincipalType, principalId: string) {
        // TODO: should this function have "ensure loaded" behavior instead?
        // expand nothing (this is typically a point request to facilitate some other operation)
        const requestTime = DateTime.utc()
        const p = await gp.getPlainAsync(`datagroups/${dataGroupId}/members/$lookup`, { principalType: type, principalId })
        const itemMap = processExpandedDataGroupMembers([p], requestTime)
        AsyncDataStore.addItemMapToStore(asyncDataGroupMembers, "DataGroupMember", itemMap, requestTime)
        return itemMap.values().next().value
    }

    function getAsyncKeyListsForDataGroups(dataGroupIds: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        // expand principal, but assume datagroup/owner are already loaded
        return getAsyncKeyListsForDataGroupMembers(dataGroupIds, "DataGroup", dataGroupStore.asyncMemberKeys, loadMode, 
            id => `datagroups/${id}/members`, "familyGroup/owner,user", dm => dm.dataGroupId!)
    }

    function getAsyncKeyListsForUsers(userIds?: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        // expand data group, but assume user is already loaded
        userIds ??= [TokenManager.userId]
        return getAsyncKeyListsForDataGroupMembers(userIds, "User", userStore.asyncDataGroupMemberKeys, loadMode, 
            id => `users/${id}/datagroups`, "dataGroup/owner", dm => dm.principalId!)
    }

    function getAsyncKeyListsForFamilyGroups(familyGroupIds: (string | undefined)[], loadMode = LoadMode.TrackOnly, inviteId?: string, code?: string) {
        // expand data group, but assume family group is already loaded
        return getAsyncKeyListsForDataGroupMembers(familyGroupIds, "FamilyGroup", familyGroupStore.asyncDataGroupMemberKeys, loadMode, 
            id => `familygroups/${id}/datagroups`, "dataGroup/owner", dm => dm.principalId!, inviteId, code)
    }


    function getMembersForDataGroups(dataGroupIds: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const keys = getAsyncKeyListsForDataGroups(dataGroupIds, loadMode).flatMap(a => a?.data?.keys ?? [])
        return getLoadedMembers(keys)
    }

    function getMembersForUsers(userIds?: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const keys = getAsyncKeyListsForUsers(userIds, loadMode).flatMap(a => a?.data?.keys ?? [])
        return getLoadedMembers(keys)
    }

    function getMembersForFamilyGroups(familyGroupIds: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const keys = getAsyncKeyListsForFamilyGroups(familyGroupIds, loadMode).flatMap(a => a?.data?.keys ?? [])
        return getLoadedMembers(keys)
    }


    async function getMembersForDataGroupsAsync(dataGroupIds: (string | undefined)[]) {
        const keyListPromises = getAsyncKeyListsForDataGroups(dataGroupIds, LoadMode.EnsureLoaded).map(a => a.whenLoadCompleted)
        const keyLists = await Promise.all(keyListPromises)
        const keys = keyLists.filter(isDefined).flatMap(list => list.keys)
        return getLoadedMembers(keys)
    }

    async function getMembersForUsersAsync(userIds?: (string | undefined)[], loadMode = LoadMode.EnsureLoaded) {
        const keyListPromises = getAsyncKeyListsForUsers(userIds, loadMode).map(a => a.whenLoadCompleted)
        const keyLists = await Promise.all(keyListPromises)
        const keys = keyLists.filter(isDefined).flatMap(list => list.keys)
        return getLoadedMembers(keys)
    }

    async function getMembersForFamilyGroupsAsync(familyGroupIds: (string | undefined)[]) {
        const keyListPromises = await getAsyncKeyListsForFamilyGroups(familyGroupIds, LoadMode.EnsureLoaded).map(a => a.whenLoadCompleted)
        const keyLists = await Promise.all(keyListPromises)
        const keys = keyLists.filter(isDefined).flatMap(list => list.keys)
        return getLoadedMembers(keys)
    }

    async function addAsync(dataGroupId: string, member: DataGroupMember) {
        const addCoreAsync = async () => {
            const p = await gp.postPlainAsync(`datagroups/${dataGroupId}/members`, member)
            return plainToDataGroupMember(p)
        }            
        const key = await AsyncDataStore.addToStoreMapAsync(asyncDataGroupMembers, "DataGroupMember", m => m.id, addCoreAsync)
        const newMember = asyncDataGroupMembers.get(key)?.data // should be present

        invalidateKeyLists(newMember)
        return key
    }

    async function deleteAsync(member: DataGroupMember) {
        await gp.deleteAsync(`datagroups/${member.dataGroupId}/members/${member.id}`)

        AsyncData.setDeleted(asyncDataGroupMembers.get(member.key!))
        invalidateKeyLists(member)
    }
    
    function plainToDataGroupMember(p: any) {
        const member = assignExisting(new DataGroupMember(), p)
        member.role = p.role ?? GroupMemberRole.Viewer // default
        member.modifiedDate = DateTimeUtil.fromAPI(p.modifiedDate);
        return member
    }

    function processExpandedDataGroupMembers(plainItems: any[], requestTime: DateTime) {
        dataGroupStore.addDataGroupsToStore(plainItems.map(p => p.dataGroup).filter(isDefined), requestTime)
        familyGroupStore.addFamilyGroupsToStore(plainItems.map(p => p.familyGroup).filter(isDefined), requestTime)
        userStore.addUsersToStore(plainItems.map(p => p.user).filter(isDefined), requestTime)
        return AsyncDataStore.createItemMap(plainItems, "DataGroupMember", plainToDataGroupMember, m => m.key!)
    }

    function getAsyncKeyListsForDataGroupMembers(sourceItemKeys: (string | undefined)[],
        sourceTypeName: string,
        relatedKeysStoreMap: Map<string, AsyncData<VersionedKeyList>>,
        loadMode: LoadMode,
        relatedItemsPath: (id: string) => string,
        relatedItemsExpand: string | undefined,
        getSourceKey: (dm: DataGroupMember) => string,
        inviteId?: string,
        code?: string) {

        const loadAsync = async (sourceKeys: string[], requestTime: DateTime) => {
            const ids = sourceKeys.filter(isDefined)
            const path = relatedItemsPath(sourceKeys.length == 1 ? sourceKeys[0] : '$list')
            const query = { inviteId, code, ids: sourceKeys.length == 1 ? undefined : ids.join() }
            const plainItems = await gp.getPlainCollectionAsync(path, relatedItemsExpand, query)
            console.log(`Loaded ${plainItems.length} data group members at ${relatedItemsPath}`)
            return processExpandedDataGroupMembers(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncKeyListsForRelatedItems(sourceItemKeys, sourceTypeName, relatedKeysStoreMap,
            asyncDataGroupMembers, "DataGroupMember", loadMode, loadAsync, getSourceKey)
    }

    function invalidateKeyLists(member: DataGroupMember | undefined) {
        if (!member)
            return
        
        const asyncKeys = dataGroupStore.asyncMemberKeys.get(member.dataGroupId!)
        AsyncData.invalidate(asyncKeys)
    
        if (member.principalType == PrincipalType.User) {
            const asyncKeysForUser = userStore.asyncDataGroupMemberKeys.get(member.principalId!)
            AsyncData.invalidate(asyncKeysForUser)
        }
        else if (member.principalType == PrincipalType.FamilyGroup) {
            const asyncKeysForFamilyGroup = familyGroupStore.asyncDataGroupMemberKeys.get(member.principalId!)
            AsyncData.invalidate(asyncKeysForFamilyGroup)
        }
    }

    function addToLoadedKeyLists(newMembers: DataGroupMember[], requestTime: DateTime) {
        const dataGroupIds = new Set(newMembers.map(m => m.dataGroupId!))
        for (const dgId of dataGroupIds) {
            const dmKeys = newMembers.filter(m => m.dataGroupId == dgId).map(m => m.key!)
            AsyncDataStore.addToLoadedKeyList(dataGroupStore.asyncMemberKeys, dgId, dmKeys, requestTime)
        }

        const userMems = newMembers.filter(m => m.principalType == PrincipalType.User)
        const userIds = new Set(userMems.map(m => m.principalId!))
        for (const userId of userIds) {
            const dmKeys = userMems.filter(m => m.principalId == userId).map(m => m.key!)
            AsyncDataStore.addToLoadedKeyList(userStore.asyncDataGroupMemberKeys, userId, dmKeys, requestTime)
        }

        const fgMems = newMembers.filter(m => m.principalType == PrincipalType.FamilyGroup)
        const fgIds = new Set(fgMems.map(m => m.principalId!))
        for (const fgId of fgIds) {
            const dmKeys = fgMems.filter(m => m.principalId == fgId).map(m => m.key!)
            AsyncDataStore.addToLoadedKeyList(familyGroupStore.asyncDataGroupMemberKeys, fgId, dmKeys, requestTime)
        }
    }

    return {
        asyncDataGroupMembers,

        getLoadedMembers,
        getAsyncMemberByKey,
        getMemberByPrincipalAsync,
        getAsyncKeyListsForDataGroups,
        getAsyncKeyListsForUsers,
        getAsyncKeyListsForFamilyGroups,
        getMembersForDataGroups,
        getMembersForUsers,
        getMembersForFamilyGroups,
        getMembersForDataGroupsAsync,
        getMembersForUsersAsync,
        getMembersForFamilyGroupsAsync,
        addAsync,
        deleteAsync,
        invalidateKeyLists,
        addToLoadedKeyLists,
    }
})
