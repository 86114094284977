<template>
  <Modal ref="modalRef" @ok="switchUser">
    <template #title>Switch user</template>
    <p>
      Are you sure you want to switch to this user?
    </p>
    <UserItem :user-id="userIdRef" />
    <p class="help-text">
      You will be signed out of your account and signed in as this user.
      Once signed in, they will not be able to switch back to your account.
    </p>
    <template #okText>Switch to user</template>
    <template #busyText>Switching...</template>
  </Modal>
</template>

<style lang="scss" scoped>
.user-item {
  margin: 1rem;
}
</style>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useTokenManager } from '@/auth/TokenManager'
import { useUserStore } from '@/gp/UserStore';
import Modal from '@/util/Modal.vue'
import UserItem from './UserItem.vue';

const userIdRef = ref<string>()
const modalRef = ref<InstanceType<typeof Modal>>()

const userStore = useUserStore()
const tokenManager = useTokenManager()

const userRef = computed(() => userStore.getAsyncUser(userIdRef.value)?.data)

defineExpose({
  open,
})

function open(userId: string) {
  userIdRef.value = userId
  modalRef.value?.open()
}

async function switchUser() {
  await tokenManager.impersonateAsync(userIdRef.value!)
  window.location.href = "/home" // reload the app
}
</script>